import {gql} from 'apollo-angular';
import {FRAGMENTS_USER_MANAGEMENT} from './_fragments';

export const QUERIES_USER_MANAGEMENT = {
  user: {
    findAll: gql`
      query users($page: Int, $itemsPerPage: Int, $name: String, $order: [UserFilter_order]!,$groups_roles: String, $orWhereFilter: [UserFilter_orWhereFilter], $enabled: Boolean) {
        users(groups_roles: $groups_roles, page: $page, name: $name, order: $order, itemsPerPage: $itemsPerPage, orWhereFilter: $orWhereFilter, enabled: $enabled) {
          paginationInfo {
            itemsPerPage
            lastPage
            totalCount
          }
          collection {
            enabled
            roles
            creationTime
            birthdate
            ...adminUser
          }
        }
      }${FRAGMENTS_USER_MANAGEMENT.user.adminUser}${FRAGMENTS_USER_MANAGEMENT.group.group}`,
    findById: gql`
      query getUser($id: ID!) {
        user(id: $id) {
          ...userAll
        }
      }${FRAGMENTS_USER_MANAGEMENT.user.userAll}${FRAGMENTS_USER_MANAGEMENT.group.group}`,
  },
  group: {
    findAll: gql`
      query groups($page: Int, $itemsPerPage: Int, $name: String, $order: [GroupFilter_order], $active: Boolean,) {
        groups(page: $page, name: $name, order: $order, itemsPerPage: $itemsPerPage, active: $active) {
          paginationInfo {
            itemsPerPage
            lastPage
            totalCount
          }
          collection {
            id
            name
            active
            roles
          }
        }
      }`,
  },
  patient: {
    findAll: gql`
      query patients($page: Int, $itemsPerPage: Int, $name: String, $order: [PatientFilter_order]!,$groups_roles: Iterable) {
        patients(groups_roles: $groups_roles,page: $page, name: $name, order: $order, itemsPerPage: $itemsPerPage) {
          paginationInfo {
            itemsPerPage
            lastPage
            totalCount
          }
          collection {
            ...patientAll
          }
        }
      }${FRAGMENTS_USER_MANAGEMENT.patient.patientAll}`,
    findById: gql`
      query getPatient($id: ID!) {
        patient(id: $id) {
          ...patientAll
        }
      }${FRAGMENTS_USER_MANAGEMENT.patient.patientAll}${FRAGMENTS_USER_MANAGEMENT.group.group}`
  },
};
