import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {
  NgPagination,
  NgPaginationEllipsis,
  NgPaginationFirst, NgPaginationLast, NgPaginationNext, NgPaginationNumber, NgPaginationPrevious
} from './components/paginator/ng-pagination/ng-pagination.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SortIconComponent} from './components/sort-icon/sort-icon.component';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {TranslationModule} from 'src/app/modules/i18n/translation.module';
import {GenericTableComponent} from './components/generic-table/generic-table.component';
import {GenericTablePageComponent} from './components/generic-table-page/generic-table-page.component';
import {SharedModule} from "../../shared.module";
import {InputSearchComponent} from "./components/input-search/input-search.component";

@NgModule({
  declarations: [
    PaginatorComponent,
    NgPagination,
    NgPaginationEllipsis,
    NgPaginationFirst,
    NgPaginationLast,
    NgPaginationNext,
    NgPaginationNumber,
    NgPaginationPrevious,
    SortIconComponent,
    GenericTableComponent,
    GenericTablePageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InlineSVGModule,
    TranslationModule,
    ReactiveFormsModule,
    SharedModule,
    InputSearchComponent,
  ],
  exports: [
    PaginatorComponent,
    NgPagination,
    SortIconComponent,
    GenericTableComponent,
    GenericTablePageComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CRUDTableModule {
}
