import {ROLE_ADMIN, ROLE_PATIENT} from './roles';

/**
 * Class to manage the configurations in the control of access to components via URL
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class AccessControl {
  /**
   * Allows you to configure the route and URL to redirect to if access is denied.
   * The path property specifies the route path, while the url property specifies the full URL to redirect to.
   */
  public static REDIRECT_ON_ACCESS_DENIED_ROUTE: {path: string, url: string} = {
    path: 'dashboard',
    url: '/dashboard',
  };
  /**
   * It allows to determine if the authenticated user in case of not having any role can access or not, by default it is false.
   */
  public static GRANT_ACCESS_IF_USER_HAS_NO_ROLE = false;
  /**
   * Enable or disable access control
   */
  public static CHECK_ACCESS = true;

  /**
   * Method to get the access list
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static getAccessControlListByRole(): {[key: string]: string[]}   {
    let accessControlByRole: Record<string, any> = {};


    const COMMON_ROUTES: any[] = [
      //here you can define al common routes regarles the role
      'profile'
    ];

    //you can create more access controls for each role of your business in the same way of  ROLE_ADMIN & ROLE_PATIENT
    accessControlByRole[ROLE_ADMIN.role] = [
      ...COMMON_ROUTES,
      'group',
      'user'
      //define all routes access by role admin
    ];
    accessControlByRole[ROLE_PATIENT.role] = [
      ...COMMON_ROUTES,
      //define all routes access by role patient
    ];

    return accessControlByRole;
  }
}
