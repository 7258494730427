import {ICrudLocale} from "../../i18n/interface";

export const PATIENT_LOCALE: ICrudLocale = {
  PATIENT_MANAGEMENT: {
    FIELD: {
      NAME: 'Nombre',
      ACTIVE: 'Activo',
      ROLES: 'Roles',
    },
    LABEL: {
      SINGULAR:'',
      PLURAL: ''
    },
    LIST: {
      TITLE: 'Listado de grupos'
    },
    BUTTON:{
      DESACCIOCIATE:'Desvincular'
    },
    ASSOCIATED_DEVICES:'Dispositivos asociados',
    DEVICES:'Dispositivos',
    ADD_DEVICE:'Añadir dispositivo',
    USE_TIME:'Tiempo de uso',
    RESPIRATORY_RATE:'Frecuencia respiratoria',
    OXYGEN_SAT:'Saturación de oxígeno',
    LAST_SYNC:'Última sincronización',
    VITAL_SINGS:'Signos Vitales',
    HEART_RATE:'Frecuencia cardíaca',
    OXYGEN_SATURATION:'Saturación de oxígeno',
    MEDIUM_SATURATION:'Saturación media',
    RECOMMENDED_FLOW:'Flujo de oxígeno recomendado',
    DELIVERED_FLOW:'Flujo de oxígeno entregado',
    RECOMMENDED:'Recomendado',
    SAFETY_RANGE:'Rango de seguridad',
    OTHER_VITAL_SINGS:'Otros signos vitales',
    FEVER:'Temperatura',
    BLOOD_PRESSURE:'Presión arterial',
    BLOOD_PRESSURE_SYSTOLIC:' Presión arterial sistólica',
    BLOOD_PRESSURE_DIASTOLIC:'Presión arterial diastólica',
    FORMER:'Anterior',
    PATHOLOGIES:'Patologías',
    TITLE_FEVER_DATA:'Editar fiebre',
    TITLE_BLOOD_PRESSURE_DATA:'Editar presión arterial',
    TITLE_ADJUSTABLE_OXYGEN:'Regulación del flujo de oxígeno',
    TITLE_ADJUSTABLE_RECOMMENDED_FLOW_OXYGEN:'Ajuste del flujo O2 recomendado',
    TITLE_UPDATE_RECOMMENDED_FLOW_OXYGEN:'Modificación del flujo O2 recomendado',
    FLOOR:'Planta',
    BED:'Cama',
  }
}
