import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {GenericSortState} from '../../models/sort.model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CustomLoaderService} from '../../../../services/custom-loader.service';

export interface IGenericTableHeader {
  label: string,
  class?: string[] | string,
  style?: { [property: string]: any },
  enabled?: boolean,
  sort?: {
    column: string,
    enabled?: boolean
  },
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class GenericTableComponent implements OnInit {

  //general options
  @Output() sortChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() rowTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() headers: IGenericTableHeader[];
  @Input() headerClass: string[] | string;
  @Input() headerStyle: { [klass: string]: any };

  @Input() items$: BehaviorSubject<any[]>;
  @Input() isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() sortState: GenericSortState;

  @Input() striped: boolean = false;
  @Input() bordered: boolean = false;
  @Input() rounded: boolean = false;
  @Input() flushStyles: boolean = false;
  @Input() showRowBorder: boolean = false;
  @Input() rowHoverable: boolean = true;
  @Input() showRowBorderDashed: boolean = false;
  @Input() darkMode: boolean = false;


  //no data options
  @Input() noDataMessage: string = 'Lo sentimos no hay información disponible.';
  @Input() noDataShowImg: boolean = true;
  @Input() noDataTemplate: TemplateRef<any> | undefined = undefined;

  @Input() showTableIfEmpty: boolean = true;

  //TODO: Por implementar
  @Input() hasCheckboxColumn: boolean = false;

  componentLoaderID: string = 'generic-table-loaderId' + self.crypto.randomUUID();

  isLoading: boolean;
  tableHasItems: boolean;
  subscriptions: Subscription[] = [];

  constructor(
    private loaderUi: CustomLoaderService,
  ) {
  }


  ngOnInit(): void {

    const isLoadingSB = this.isLoading$?.subscribe(value => {
      this.isLoading = value;

      if (this.isLoading) {
        this.blockTableUI()
        return;
      }
      this.unBlockTableUI()
    });

    const tableData = this.items$?.subscribe(data => {
      this.tableHasItems = data.length > 0;
    });

    this.subscriptions.push(isLoadingSB);
    this.subscriptions.push(tableData);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => {
      sb.unsubscribe();
    })
  }

  public blockTableUI() {
    this.loaderUi.startLoader(this.componentLoaderID)
    // this.blockUI.start();
  }

  public unBlockTableUI() {
    // this.blockUI.reset()
    this.loaderUi.stopLoader(this.componentLoaderID)
  }

  public sortingEmitter(column: string) {
    this.sortChange.emit(column)
  }

}
