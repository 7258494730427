import {AsideDynamicItemConfigType} from '../app/_metronic/layout/components/aside/aside-menu/aside-menu.component';
import {GET_ALL_ROLES_BY_KEY, ROLE_ADMIN} from './roles';

export const ASIDE_CONFIG: AsideDynamicItemConfigType[] = [
  {
    title: 'Dashboard',
    route: '/management/patient',
    pageTitle: 'Dashboard',
    svg: './assets/media/icons/officials/export_white/w-dashboard.svg',
    roles:[...GET_ALL_ROLES_BY_KEY('role')]
  },
  {
    header: 'TEXT.ADMINISTRATION',
    roles:[ROLE_ADMIN.role]
  },
  {
    title: 'Dispositivos',
    route: '/management/device',
    svg: './assets/media/icons/officials/export_white/w-settings.svg',
    roles:[ROLE_ADMIN.role]
  },
  // {
  //   title: 'GROUP_MANAGEMENT.LABEL.SINGULAR',
  //   route: 'management/patient/list/archived',
  //   svg: './assets/media/icons/duotune/general/gen051.svg',
  //   roles:[ROLE_ADMIN.role]
  // },
  // {
  //   title: 'USER_MANAGEMENT.LABEL.SINGULAR',
  //   route: '/management/user',
  //   svg: './assets/media/icons/duotune/general/gen049.svg',
  //   roles:[ROLE_ADMIN.role]
  // },
]
