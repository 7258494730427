import {PAGINATOR_LOCALE_EN} from "../../shared/modules/crud-table/components/paginator/i18n/locale";
import {USER_MANAGEMENT_LOCALE_ES} from "../../user-management/i18n/locale";
import {GROUP_LOCALE} from "../../group-management/i18n/locale";
import {I18N_PARTIALS_EN} from "../partials/en";
import {ILangVocab} from "../../../../_config/languages";

export const locale: ILangVocab = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Choose your language',
    },
    MENU: {
      DASHBOARD: 'Dashboard'
    },
    TEXT: {
      MANAGEMENT: 'Management',
      ADMINISTRATION: 'Administration',
      SELECT: 'Select',
      ONLINE_TITLE: 'Connection restored',
      ONLINE_MESSAGE: 'Back to online',
      OFFLINE_TITLE: 'Lost connection!',
      OFFLINE_MESSAGE: 'Connection lost! You are not connected to internet',
      NO_ITEMS_FOUND: 'No items found',
    },
    GENDER: {
      MALE: 'Male',
      FEMALE: 'Female',
      INTERSEXUAL: 'Intersexual',
      OTHER: 'Other',
      NON_BINARY: 'No binario',
      UNSPECIFIED: 'No lo quiere especificar',
    },
    //TODO: Adicionar los translations del modulo auth
    ...I18N_PARTIALS_EN,
    ...PAGINATOR_LOCALE_EN,
    ...USER_MANAGEMENT_LOCALE_ES,
    ...GROUP_LOCALE,
  }
};
