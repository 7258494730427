import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { TokenModel } from '../../models/token.model';
import {AuthService} from "../auth.service";

const API_USERS_URL = `${environment.apiUrl}/api/users`;
const API_LOGIN_URL = `${environment.apiUrl}/authentication_token`;
const API_REFRESH_TOKEN_URL = `${environment.apiUrl}/api/refresh_token`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<TokenModel> {
    return this.http.post<TokenModel>(API_LOGIN_URL, {
      email,
      password,
    });
  }

  // Method for refresh token
  refreshToken(): Observable<any> {
    return this.http.post<TokenModel>(API_REFRESH_TOKEN_URL, {
      refresh_token: localStorage.getItem(AuthService.REFRESH_TOKEN),
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/reset-password-code`, {
      email,
    });
  }

  getUserByTokenStorage(): Observable<UserModel> {
    // const httpHeaders = new HttpHeaders({
    //   Authorization: `Bearer ${token}`,
    // });
    return this.http.get<UserModel>(`${API_USERS_URL}/`+localStorage.getItem(AuthService.AUTH_USER_ID));
  }

  changePasswordWithCode(parameters: any): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/check-password-code`, parameters);
  }
}
