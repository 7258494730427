import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS, APOLLO_FLAGS} from 'apollo-angular';
import {ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from "../environments/environment";
import {AuthService} from "./modules/auth";
import {HttpHeaders} from "@angular/common/http";
// import {InMemoryCache} from "apollo-cache-inmemory" {freezeResults: false};

const uri = `${environment.apiUrl}/api/graphql`; // <-- add the URL of the GraphQL server here

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true, // enable it here
        useMutationLoading: true, // enable it here
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        const http = httpLink.create({uri});
        const middleware = new ApolloLink((operation, forward, ) => {
          operation.setContext({
            headers: new HttpHeaders().set(
              'Authorization',
              `Bearer ${localStorage.getItem(AuthService.LOCAL_STORAGE_TOKEN) || null}`,
            ),
          });
          return forward(operation);
        });

        const link = middleware.concat(http);

        return {
          link,
          cache: new InMemoryCache(),
          defaultOptions: {
            query: {
              fetchPolicy: 'network-only'
            },
          }
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
