import {AfterViewInit, booleanAttribute, Directive, ElementRef, HostBinding, Input, Renderer2} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
type PasswordStrengthResponseType =
  { passwordStrength: { maxLength: number, actualLength: number } }
  | ValidationErrors
  | null

@Directive({
  selector: '[passwordSecurity]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordSecurityAdvanceDirective,
      multi: true
    }
  ]
})
/**
 * This class is used for password input fields that provides additional
 * features such as password visibility toggling, password strength meter, and reactive validation.
 *
 *  1.  `passwordMoveHostSiblings` : A boolean input property that determines whether all sibling nodes of the directive
 *  host should be moved to the new structure created. This is useful for form fields with input and error message elements.
 *  Default value is  `true` .
 *  2.  `enablePasswordToggle` : A boolean input property that determines whether the password visibility toggle should
 *  be displayed.
 *  Default value is  `true` .
 *  3.  `enablePasswordStrengthMeter` : A boolean input property that determines whether the password strength meter should
 *  be displayed.
 *  Default value is  `true` .
 *  4.  `enablePasswordReactiveValidation` : A boolean input property that determines whether reactive validation
 *  should be applied to the field.
 *  Default value is  `false` .
 *  5.  `passwordStrengthOptions` : An input property that allows configuring the password strength meter.
 *  Default options include a minimum length of 10
 *  6.  `passwordStrengthOptions` : An input property that allows configuring the password strength meter color coding for different strength levels
 *  Default options include { weak?: '#ed3d3d', medium?: '#ed9e3d', normal?: '#edd83d', strong?: '#3da4ed', veryStrong?: '#3dc763'}
 *  @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class PasswordSecurityAdvanceDirective implements AfterViewInit, Validator {

  private shown = false;

  @HostBinding('class.psm-toggle') get isToggle(): boolean {
    return this.enablePasswordToggle
  }

  @HostBinding('attr.data-psm') dataPsm = 'true';


  /**
   * It allows us to establish if we want all the sibling nodes of the directive host to be moved to the new structure created,
   * this is useful when we have a form field that has the input + the div with the errors to display.
   *
   * @default false
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  @Input({transform: booleanAttribute}) passwordMoveHostSiblings: boolean = true;

  /**
   * Allows us to define if we want to see the toggle for the visibility of the password.
   *
   * @default true
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  @Input({transform: booleanAttribute}) enablePasswordToggle: boolean = true;

  /**
   * Allows us to define if we want to see the password strength in a progress bar style.
   *
   * @default true
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  @Input({transform: booleanAttribute}) enablePasswordStrengthMeter: boolean = true;

  /**
   * Determines if reactive validation is applied to the field
   *
   * @default true
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  @Input({transform: booleanAttribute}) enablePasswordReactiveValidation: boolean = true;


  /**
   * @default {showIconClass: 'bi bi-eye', hideIconClass: 'bi bi-eye-slash',length: 10 }
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  @Input() passwordStrengthOptions: {
    length: number,
    showIconClass?: string,
    hideIconClass?: string,
    // hasSymbols?: boolean,
    // hasNumber?: boolean,
    // lowercase?: boolean
    // hasSpecialCharacters?: boolean,
  } = {
    length: 10,
    // hasSymbols: true,
    // hasNumber: true,
    // lowercase: true,
    // hasSpecialCharacters: true
  }

  /**
   * @default { weak?: '#ed3d3d', medium?: '#ed9e3d', normal?: '#edd83d', strong?: '#3da4ed', veryStrong?: '#3dc763'}
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  @Input() passwordStrengthColors?: {
    weak?: string,
    medium?: string,
    normal?: string,
    strong?: string,
    veryStrong?: string,
  } = {}

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {

    if (this.enablePasswordToggle) {
      const style = this.renderer.createElement('style');
      const css = '.was-validated .form-control.psm-toggle:invalid, .form-control.psm-toggle.is-invalid { padding-right: calc(1.5em + 3rem);} .was-validated :invalid ~ .password-toggle, .is-invalid ~ .password-toggle, .was-validated :valid ~ .password-toggle, .is-valid ~ .password-toggle {right: 20px !important; top: 25px !important; transition: all ease-in-out 0.15s;}';
      this.renderer.appendChild(style, this.renderer.createText(css));
      this.renderer.appendChild(document.head, style);
    }

  }

  ngAfterViewInit() {

    const parent = this.renderer.parentNode(this.el.nativeElement);

    //get actual siblings
    const hostSiblings: HTMLElement[] = Array.from(parent.children);
    const nativeElementIndex = hostSiblings.indexOf(this.el.nativeElement);

    // create new container div
    let newContainerDiv = this.renderer.createElement('div');
    this.renderer.setAttribute(newContainerDiv, 'style', 'position: relative!important');

    //append host to new container
    this.renderer.insertBefore(parent, newContainerDiv, this.el.nativeElement);
    this.renderer.appendChild(newContainerDiv, this.el.nativeElement);

    this.renderer.appendChild(parent, newContainerDiv);

    if (this.enablePasswordToggle) {
      // create toggle
      const span = this.renderer.createElement('span');
      this.renderer.setAttribute(span, 'data-toggle', '');
      this.renderer.addClass(span, 'password-toggle');
      this.renderer.setStyle(span, 'cursor', 'pointer');
      this.renderer.setStyle(span, 'position', 'absolute');
      this.renderer.setStyle(span, 'margin-right', '0.5rem');
      this.renderer.setStyle(span, 'transform', 'translate(-50%, -50%)');
      this.renderer.setStyle(span, 'right', '0');
      this.renderer.setStyle(span, 'top', '50%');

      const {
        showIconClass = 'bi bi-eye',
        hideIconClass = 'bi bi-eye-slash',
      } = this.passwordStrengthOptions;

      const hideIcon: HTMLElement = this.renderer.createElement('i');
      hideIcon.className = hideIconClass
      hideIcon.style.fontSize = '20px'

      const showIcon: HTMLElement = this.renderer.createElement('i');
      showIcon.className = showIconClass;
      showIcon.style.display = 'none'
      showIcon.style.fontSize = '20px'

      this.renderer.appendChild(span, hideIcon);
      this.renderer.appendChild(span, showIcon);

      this.renderer.appendChild(newContainerDiv, span);

      this.renderer.listen(span, 'click', () => {
        hideIcon.style.display = hideIcon.style.display === 'none' ? 'block' : 'none';
        showIcon.style.display = showIcon.style.display === 'none' ? 'block' : 'none';
        this.shown = !this.shown;
        this.el.nativeElement.setAttribute('type', this.shown ? 'text' : 'password');
      });
    }

    if (this.passwordMoveHostSiblings) {

      hostSiblings.forEach((sibling: HTMLElement, siblingIndex: number) => {
        if (siblingIndex > nativeElementIndex) {
          this.renderer.appendChild(newContainerDiv, sibling);
        }
      });

    }

    if (this.enablePasswordStrengthMeter) {
      this.el.nativeElement.insertAdjacentHTML('afterend', `
     <div class="password-strength"
      style="
      --percent: 0%; --progress-color: #ed3d3d; border-radius: 0px 0px 45px 45px; box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5em 0.5em inset; height: 5px; overflow: hidden; position: relative; transform: translateZ(0px); width: 99.5%; background-color: var(--progress-color);top: -5px;
        "
        >
        <div class="progress_inline_bar"
         style="
            background-color: #ececec;
            box-shadow: inset 0 0.5em 0.5em rgba(0, 0, 0, 0.05);
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 500ms ease-out;
            transform: translateX(var(--percent));"
            ></div>
    </div>
    `);

    }


    if (this.enablePasswordStrengthMeter) {
      this.updatePasswordStrength(this.el.nativeElement.value);
    }
  }

  validate(control: AbstractControl): PasswordStrengthResponseType {
    const inputValue = control.value;

    if (this.enablePasswordStrengthMeter) {
      this.updatePasswordStrength(inputValue);
    }

    if (
      this.enablePasswordReactiveValidation &&
      (
        inputValue.length < this.passwordStrengthOptions.length || //maxLength
        !inputValue.match(/[a-z]/) || //lowercase
        !inputValue.match(/[A-Z]/) || //uppercase
        !inputValue.match(/\d/) || //numbers
        !inputValue.match(/[!@#$%&*ñáéíóúç]/) //special characters & symbols
      )
    ) {
      return {
        passwordStrength: {
          maxLength: this.passwordStrengthOptions.length,
          actualLength: inputValue.length
        }
      }
    }


    return null;
  }

  private updatePasswordStrength(password: string): void {
    const meter = this.el.nativeElement.nextElementSibling;
    if (meter) {
      const strength = this.calculatePasswordStrength(password);

      const progressBar = meter.querySelector('.progress_inline_bar')

      if (progressBar) {
        const strengthPercent = (strength * 100) / 5;

        const {
          weak = '#ed3d3d',
          medium = '#ed9e3d',
          normal = '#edd83d',
          strong = '#3da4ed',
          veryStrong = '#3dc763'
        } = this.passwordStrengthColors || {};

        meter.style.setProperty('--progress-color',
          strength <= 1 ? weak :
            strength === 2 ? medium :
              strength === 3 ? normal :
                strength === 4 ? strong :
                  strength >= 5 ? veryStrong :
                    '');


        progressBar.style.setProperty('--percent', `${String(strengthPercent)}%`);
      }

    }
  }

  private calculatePasswordStrength(password: string): number {
    let strength = 0;

    if (password.match(/[a-z]/)) {
      strength++;
    }

    if (password.length >= this.passwordStrengthOptions.length) {
      strength++;
    }

    if (password.match(/[A-Z]/)) {
      strength++;
    }

    if (password.match(/\d/)) {
      strength++;
    }

    if (password.match(/[@$!%*?&]/)) {
      strength++;
    }

    return strength;
  }
}
