import {Injectable} from '@angular/core';
import {GenericCRUDTableService} from "../../shared/modules/crud-table";
import {Patient, User, UserExtended} from "../model/user";
import {MutationResult} from "apollo-angular";
import {QUERIES_USER_MANAGEMENT} from "../_graphql/_queries";
import {ApolloQuery, MUTATION_ACTION} from "../../shared/services/apollo-helper.service";
import {BehaviorSubject} from "rxjs";
import {HelperTools} from "../../shared/services/helperTools";

export enum UserInstanceEnum {
  user = 'User',
  patient = 'Patient',
  connectionUser = 'users',
  connectionPatient = 'patients',
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericCRUDTableService<UserExtended> {

  getApolloQueryForTable(): ApolloQuery {
    return QUERIES_USER_MANAGEMENT.user.findAll;
  }

  /**
   * Delete a user from the server
   */
  deleteInstance(id: string): Promise<MutationResult> {
    //we return a promise to remove
    return this.mutate({
      action: MUTATION_ACTION.delete,
      instance: this.inheritUserInstanceFromID(id),
      enabledSecurity: true,
      data: {
        id: id
      }
    })
  }

  toggleState(id: string, state: boolean) {
    return this.mutate({
        action: MUTATION_ACTION.update,
        instance: this.inheritUserInstanceFromID(id),
        data: {id: id, enabled: state},
        isLoading$: this._isLoading$,
        enabledSecurity: true
      }
    )
  }

  public inheritUserInstanceFromID(id: string): UserInstanceEnum.user | UserInstanceEnum.patient {

    //get endpoint based on iri
    let iriEndpoint = HelperTools.getIriEndpoint(id);

    if (!iriEndpoint) {
      throw new Error(`No se pudo determinar la instancia de este IRI "${id}"`)
    }

    return iriEndpoint === UserInstanceEnum.connectionPatient ? UserInstanceEnum.patient : UserInstanceEnum.user
  }


  fetchAllGroupsTypes(isLoadingObserver?: BehaviorSubject<boolean>): Promise<any> {
    return this.apolloHelper.fetch({
      query: QUERIES_USER_MANAGEMENT.group.findAll,
      isCollection: true,
      variables: {active: true},
      isLoading$: isLoadingObserver
    })
  }

  iriToId(id: string): number {
    return HelperTools.iriToId(id);
  }
}
