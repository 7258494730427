import {Traceable} from "./traceable";
import {TypeName} from "./type-name";

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface IBaseEntity {
  updateFrom(_instance: unknown): void
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export abstract class AbstractBaseEntity implements Traceable, TypeName, IBaseEntity {
  id?: number | string;
  creationTime: number;
  updatedTime: number;
  __typename: string;

  public abstract updateFrom(_instance: unknown): void

}
