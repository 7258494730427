<ng-template #noDataDefaultTemplate>

  <ng-container *ngIf="undefined !== noDataTemplate">
    <ng-template
      [ngTemplateOutlet]="noDataTemplate"
      [ngTemplateOutletContext]="{message: noDataMessage}"
    ></ng-template>
  </ng-container>

  <ng-container *ngIf="undefined === noDataTemplate">
    <div class="d-flex flex-column align-items-center card-rounded mt-5 p-5">

      <div *ngIf="noDataShowImg" class="text-center flex-shrink-0 mb-5">
        <img width="100" src="assets/media/svg/no_data.svg" class="" alt="no_data">
      </div>

      <div class="mb-0 fs-6">
        <div class="text-muted text-center fw-bold lh-lg mb-2" [innerText]="noDataMessage | translate"></div>
      </div>

    </div>
  </ng-container>

</ng-template>

<ng-template #emptyTemplate>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!showTableIfEmpty">
      <ng-template [ngTemplateOutlet]="noDataDefaultTemplate"></ng-template>
    </ng-container>
    <ng-container *ngIf="showTableIfEmpty">
      <tr>
        <td colspan="100">
          <ng-template [ngTemplateOutlet]="noDataDefaultTemplate"></ng-template>
        </td>
      </tr>
    </ng-container>
  </ng-container>
</ng-template>


<div *ngIf="tableHasItems || (!tableHasItems && showTableIfEmpty); else emptyTemplate">
  <ngx-ui-loader [loaderId]="componentLoaderID"></ngx-ui-loader>

  <div class="table-responsive angular-bootstrap-table">
    <table
      class="table table-head-custom table-vertical-center overflow-hidden table-row-gray-200 align-middle gy-3 gx-5 table-rounded"
      [class.table-striped]="tableHasItems ? striped : false"
      [class.table-rounded]="rounded"
      [class.border]="bordered"
      [class.table-row-bordered]="showRowBorder"
      [class.table-dark]="darkMode"
      [class.table-row-dashed]="showRowBorderDashed"
      [class.table-flush]="flushStyles"
      [class.table-hover]="rowHoverable"
    >
      <thead [ngClass]="headerClass" [ngStyle]="headerStyle">
      <tr>
        <ng-container *ngFor="let header of headers">
          <th *ngIf="header.enabled || undefined === header.enabled"
              class="fw-boldest"
              [ngClass]="header.class || ''"
              [ngStyle]="header.style || {}"
              [class.cursor-pointer]="(undefined === header.sort?.enabled && undefined !==  header.sort?.column) || (header.sort?.enabled && undefined !== header.sort?.column)"
          >
            {{ header.label | translate }}
            <app-sort-icon
              *ngIf="(undefined === header.sort?.enabled && undefined !==  header.sort?.column) || (header.sort?.enabled && undefined !== header.sort?.column)"
              [column]="header.sort?.column || 'column'"
              [activeColumn]="sortState.column"
              [activeDirection]="sortState.direction"
              (sort)="sortingEmitter($event)">
            </app-sort-icon>
          </th>
        </ng-container>

      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="tableHasItems; else emptyTemplate">
        <tr
          *ngFor="let item of items$ | async ; index as index">
          <ng-template
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{
             data: item,
             index: index + 1
            }"
          ></ng-template>
        </tr>
      </ng-container>
      </tbody>

      <tfoot *ngIf="undefined !== footerTemplate">
      <tr>
        <ng-template
          [ngTemplateOutlet]="footerTemplate"
          [ngTemplateOutletContext]="{data: items$|async}"
        ></ng-template>
      </tr>
      </tfoot>

    </table>
  </div>
</div>
