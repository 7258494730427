import {ICrudLocale} from "../../i18n/interface";

export const USER_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  USER_MANAGEMENT: {
    LABEL: {
      SINGULAR: 'Usuario',
      PLURAL: 'Usuarios',
    },
    FIELD: {
      NAME: 'Nombre',
      USERNAME: 'Usuario',
      EMAIL: 'Email',
      GENDER: 'Género',
      ENABLED: 'Activo',
      LASTNAME: 'Apellido',
      PASSWORD: 'Contraseña',
      MOBILE: 'Teléfono',
      DNI: 'Dni',
      GROUPS: 'Grupos',
      BIRTHDATE: 'Fecha de nacimiento',
      SELF_GENERATED_PASSWORD: 'Contraseña autogenerada',
    }
    ,
    LIST: {
      TITLE: 'Listado de usuarios',
    },
  }
};
