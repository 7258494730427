// tslint:disable:variable-name
import {BehaviorSubject, Subscription} from 'rxjs';
import {GenericPaginatorState} from '../models/paginator.model';
import {GenericSearchableFieldsType, IGenericTableService, IGenericTableState} from '../models/table.model';
import {GenericSortState} from '../models/sort.model';
import {GenericGroupingState} from '../models/grouping.model';
import {Apollo, QueryRef} from 'apollo-angular';
import {ApolloHelperService, ApolloQuery} from '../../../services/apollo-helper.service';
import {inject, Inject, Injectable} from '@angular/core';

class DEFAULT_STATE implements IGenericTableState {
  filter = {};
  searchableFields = [];
  paginator = new GenericPaginatorState();
  sorting = new GenericSortState();
  searchTerm = '';
  grouping = new GenericGroupingState();
}

@Injectable({
  providedIn: 'root'
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export abstract class GenericTableService<T> implements IGenericTableService {

  //Services DI
  protected readonly apollo: Apollo = inject(Apollo);

  // Private fields
  protected _items$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected _isTableFilterActive$ = new BehaviorSubject<boolean>(false);
  protected _isLoading$ = new BehaviorSubject<boolean>(false);
  protected _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  protected _tableState$ = new BehaviorSubject<IGenericTableState>(new DEFAULT_STATE());
  protected _errorMessage = new BehaviorSubject<string>('');
  protected _querySubscription: Subscription;
  private _pollInterval: number = 60000;
  private _apolloPostQuery: QueryRef<any>;
  private _defaultQueryFilters: Record<any, any>;

  protected constructor(
  ) {
  }

  /**
   * setTheQueryToUseForTheListing
   */
  public abstract getApolloQueryForTable(): ApolloQuery;

  //<editor-fold desc="Getter & Setter">

  get defaultQueryFilters() {
    return this._defaultQueryFilters;
  }
  get isTableFilterActive$() {
    return this._isTableFilterActive$;
  }

  set defaultQueryFilters(value) {
    this._defaultQueryFilters = value;
  }

  get items$() {
    return this._items$;
  }

  get itemsAsObservable$() {
    return this._items$.asObservable();
  }

  get isLoading$() {
    return this._isLoading$;
  }

  get isLoadingAsObservable$() {
    return this._isLoading$.asObservable();
  }

  get isFirstLoading$() {
    return this._isFirstLoading$;
  }

  get isFirstLoadingAsObservable$() {
    return this._isFirstLoading$.asObservable();
  }

  get errorMessage$() {
    return this._errorMessage;
  }

  get errorMessageAsObservable$() {
    return this._errorMessage.asObservable();
  }

  get querySubscription() {
    return this._querySubscription;
  }

  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }

  get filter() {
    return this._tableState$.value.filter;
  }

  get sorting() {
    return this._tableState$.value.sorting;
  }

  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }

  get grouping() {
    return this._tableState$.value.grouping;
  }

  get pollInterval(): number {
    return this._pollInterval;
  }

  set pollInterval(value: number) {
    this._pollInterval = value;
  }

  get tableSearchableFields(): GenericSearchableFieldsType {
    return this._tableState$.value.searchableFields;
  }

  set tableSearchableFields(value: GenericSearchableFieldsType) {
    this._tableState$.value.searchableFields = value;
  }

  //</editor-fold>

  public onInit() {

  }

  public onDestroy() {

  }

  /**
   * Perform update query data
   */
  public async refreshApolloQuery() {
    this._isLoading$.next(true)
    await this._apolloPostQuery?.refetch().finally(() => this._isLoading$.next(false));
  }

  public fetchApollo(query?: ApolloQuery) {

    let apolloQuery = this.getApolloQueryForTable();

    if (undefined !== query) {
      apolloQuery = query;
    }

    if (undefined === apolloQuery) {
      throw new Error('You cant perform fetchApollo() without a query defined, you can provide one through the' +
        ' fetchApollo(query) itself or from the constructor of the service in the apolloQuery key');
    }

    this._isLoading$.next(true);

    const searchOptions: Record<any, any> = {
      page: this.paginator.page,
      order: [],
      itemsPerPage: this.paginator.itemsPerPage,
    };

    //apply ordering
    if (this.sorting.column !== 'id') {
      const sorting = {};
      // @ts-ignore
      sorting[this.sorting.column] = this.sorting.direction;
      // @ts-ignore
      searchOptions.order.push(sorting);
    }

    // apply filters
    if (undefined !== this.defaultQueryFilters) {
      Object.assign(searchOptions, this.defaultQueryFilters);
    }

    let filters: any = this._tableState$.value.filter;
    if (Object.keys(filters).length > 0) {
      Object.assign(searchOptions, filters);
    }

    //method to extend and override the options sent to the query
    this.processSearchableFields(searchOptions)

    //query has filters
    this._isTableFilterActive$.next(Object.keys(filters).length > 0)

    //execute query
    this._apolloPostQuery = this.apollo.watchQuery<any>({
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      query: apolloQuery,
      pollInterval: this.pollInterval,
      variables: searchOptions
    });

    // destroy previous subscription
    if (undefined !== this.querySubscription) {
      this.querySubscription.unsubscribe();
    }

    this._querySubscription = this._apolloPostQuery.valueChanges.subscribe({
        next: ({data, loading, errors}) => {
          this._isLoading$.next(loading);

          if (undefined !== errors) {
            this._errorMessage.next(errors[0].message);
          }

          if (data !== undefined) {
            const queryName = ApolloHelperService.getConnectionName(data);

            if (queryName) {
              const paginationInfo = data[queryName].paginationInfo;
              if (undefined === paginationInfo) {
                throw new Error('GenericTableService::fetchApollo -> The paginationInfo key is not defined within the query results.')
              }

              this.patchStateApolloWithoutFetch({
                paginator: this.paginator.recalculatePaginator(paginationInfo),
              });

              this.setItems(data[queryName].collection);
            }

          }
        },
        error: (error) => {
          console.error(error);
          throw new Error('GenericTableService::fetchApollo subscription failed.')
        },
        complete: () => {
          console.log('complete')
        }
      }
    );
  }

  public resetTableState(): void {
    this.patchStateApolloWithoutFetch(new DEFAULT_STATE());
  }

  public resetDefaultServiceState() {
    this.resetTableState();
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._errorMessage.next('');
  }

  public async patchStateApollo(patch: Partial<IGenericTableState>) {
    this.patchStateApolloWithoutFetch(patch);
    this.fetchApollo();
  }

  public patchStateApolloWithoutFetch(patch: Partial<IGenericTableState>) {
    const newState = Object.assign(this._tableState$.value, patch);

    this._tableState$.next(newState);
  }

  public setItems(data: T[]) {
    this._items$.next(data);
  }

  public setIsLoading(state: boolean) {
    this._isLoading$.next(state)
  }

  private processSearchableFields(searchOptions: Record<any, any>) {
    const searchTerm = this.searchTerm;
    this._isTableFilterActive$.next(false);
    if (searchTerm.trim().length > 0) {
      this._isTableFilterActive$.next(true);
      this.tableSearchableFields.forEach(field => {

        if ('string' === typeof field) {
          searchOptions[field] = searchTerm;
        }

        if ('object' === typeof field) {
          Object.entries(field).forEach(item => {
            const [key, value] = item;

            let filtersArray: any[] = [];
            value.forEach(filterKey => {
              const filterObject: Record<any, any> = {};
              filterObject[filterKey] = searchTerm;
              filtersArray.push(filterObject);
            });

            searchOptions[key] = filtersArray;
          });
        }


      });
    }
  }

}
