import {Injectable} from '@angular/core';
import Swal, {SweetAlertOptions, SweetAlertResult} from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class SweetAlertService {

  private _defaultOptions: SweetAlertOptions = {
    confirmButtonText: 'Si, seguro',
    cancelButtonText: 'Cancelar',
    timerProgressBar: false,
    focusConfirm: false,
    focusDeny: false,
    focusCancel: false,
    returnFocus: false,
    buttonsStyling: true,
    customClass: {
      confirmButton: 'btn btn-primary rounded',
      denyButton: 'btn btn-danger rounded',
      cancelButton: 'btn text-white rounded',
    },
  };

  constructor() {
  }

  get defaultOptions(): SweetAlertOptions {
    return this._defaultOptions;
  }

  set defaultOptions(options: SweetAlertOptions) {
    this._defaultOptions = options;
  }

  //<editor-fold desc="Public Methods">
  public success(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{
        text: 'Operación completada correctamente',
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1000,
      },
      ...options
    });
  }

  public error(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{icon: 'error'},
      ...options
    });
  }

  public warning(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{icon: 'warning'},
      ...options
    });
  }

  public info(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{icon: 'info'},
      ...options
    });
  }

  public confirm(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return new Promise<SweetAlertResult>((resolve, reject) => {
      this.simpleAlert({
        ...this.defaultOptions,
        ...{
          title: '¿Está seguro?',
          text: '¡No podrás revertir esta operación!',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true,
        },
        ...options
      })
        .then((result: SweetAlertResult) => {
          if (result.isConfirmed) {
            resolve(result);
          } else {
            reject(result);
          }
        })
        .catch(reason => {
          reject(reason);
        })
      ;
    })
  }

  //</editor-fold>

  private simpleAlert(options: SweetAlertOptions): Promise<SweetAlertResult> {

    const mergedOptions = {
      ...this.defaultOptions,
      ...{
        text: 'Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.',
        confirmButtonText: '¡Ok lo tengo!',
      },
      ...options
    };

    return Swal.fire(mergedOptions);
  }

}
