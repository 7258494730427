<!-- Search toolbar -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div
    class="btn btn-icon btn-active-light-primary position-relative"
    [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
  </div>
  <app-search-result-inner></app-search-result-inner>
</div>-->

<!-- Activities -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  &lt;!&ndash; begin::Drawer toggle &ndash;&gt;
  <div
    class="btn btn-icon btn-active-light-primary"
    [ngClass]="toolbarButtonHeightClass"
    id="kt_activities_toggle"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
  </div>
  &lt;!&ndash; end::Drawer toggle &ndash;&gt;
</div>-->

<!-- NOTIFICATIONS -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div
    class="btn btn-icon btn-active-light-primary position-relative"
    [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
  </div>
  <app-notifications-inner></app-notifications-inner>
</div>-->

<!-- CHAT -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  &lt;!&ndash; begin::Menu wrapper &ndash;&gt;
  <div
    class="btn btn-icon btn-active-light-primary position-relative"
    [ngClass]="toolbarButtonHeightClass"
    id="kt_drawer_chat_toggle"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>
    <span
      class="
        bullet bullet-dot
        bg-success
        h-6px
        w-6px
        position-absolute
        translate-middle
        top-0
        start-50
        animation-blink
      "
    ></span>
  </div>
  &lt;!&ndash; end::Menu wrapper &ndash;&gt;
</div>-->

<!-- Quick links -->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div
    class="btn btn-icon btn-active-light-primary"
    [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom"
  >
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'"
      class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"
    ></span>/var/www/emily-backoffice
  </div>
  <app-quick-links-inner></app-quick-links-inner>
</div>
begin::User -->
<div
  class="d-flex align-items-center"
  [ngClass]="toolbarButtonMarginClass"
  id="kt_header_user_menu_toggle">
  <!--Notification buuton -->
<!--  <div class="cursor-pointer d-flex align-items-center me-2 position-relative"-->
<!--       [ngClass]="toolbarUserAvatarHeightClass"-->
<!--       data-kt-menu-trigger="click"-->
<!--       data-kt-menu-attach="parent"-->
<!--       data-kt-menu-placement="bottom-end"-->
<!--       data-kt-menu-flip="bottom"-->
<!--       style="width: 40px;height: 40px;border-radius: 10px;background-color: white;">-->
<!--    <img src="./assets/media/general/Iconly/Curved/Notification.svg" alt="avatar" width="24" style="margin-left: 7px" />-->
<!--    <div class="text-white d-flex justify-content-center align-items-center position-absolute" style="top: -5px;right: -2px;width: 16px;height: 16px;border-radius: 50%;background: var(&#45;&#45;color-text-red);font-weight: bold"></div>-->
<!--  </div>-->
  <!-- begin::Toggle -->
  <div
    class="cursor-pointer "
    [ngClass]="toolbarUserAvatarHeightClass"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom"
    style="width: 150px;height: 40px;border-radius: 10px; background-color: white">
    <img src="./assets/media/icons/officials/export_blue/b-user.svg" alt="avatar" style="margin-left: 10px" />
    <label class="mx-2 mt-2">
      {{ userData()?.name }} ...

    </label>
  </div>
  <!-- end::Toggle  -->

  <app-user-inner></app-user-inner>
</div>
<!-- end::User -->

<!--<ng-container *ngIf="headerLeft === 'menu'">-->
<!--  <div-->
<!--    class="d-flex align-items-center d-lg-none ms-2 me-n3"-->
<!--    title="Show header menu"-->
<!--  >-->
<!--    <div-->
<!--      class="-->
<!--        btn btn-icon btn-active-light-primary-->
<!--        w-30px-->
<!--        h-30px-->
<!--        w-md-40px-->
<!--        h-md-40px-->
<!--      "-->
<!--      id="kt_header_menu_mobile_toggle"-->
<!--    >-->
<!--      <span-->
<!--        [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'"-->
<!--        class="svg-icon svg-icon-1"-->
<!--      ></span>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-container>-->
