import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {GenericTableService} from '../../services/table.service';
import {IGenericTableHeader} from '../generic-table/generic-table.component';
import {AbstractGenericListHandler, IConfigureList} from '../../abstract/generic-list/generic-list';
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'app-generic-table-page',
  template: `
    <ng-template #actionTemplate>
      <ng-template
        *ngIf="prependActions"
        [ngTemplateOutlet]="actionsTemplate"
        [ngTemplateOutletContext]="{
             genericSearchGroup: genericSearchGroup,
             genericSearchFormControlName: genericSearchFormControlName,
            }">
      </ng-template>

      <a *ngIf="showRefreshBtn" type="button" class="btn btn-secondary" (click)="applyRefreshPatch()">
        <i *ngIf="showRefreshBtnIcon" class="{{refreshBtnIcon}}"></i>
        {{ refreshBtnTitle | translate }}
      </a>

      <ng-template
        *ngIf="!prependActions"
        [ngTemplateOutlet]="actionsTemplate"
        [ngTemplateOutletContext]="{
             genericSearchGroup: genericSearchGroup,
             genericSearchFormControlName: genericSearchFormControlName,
            }">
      </ng-template>
    </ng-template>

    <kt-card
      [cardTitle]="title"
      [toolbarActionTemplate]="actionTemplate">

      <app-input-search
        *ngIf="enableGlobalSearch"
        [formGroup]="genericSearchGroup"
        [controlName]="genericSearchFormControlName"
        [placeholder]="globalSearchPlaceholder"
        [class]="globalSearchNgClass">
      </app-input-search>

      <div *ngIf="undefined !== tableCaptionTemplate" class="">
        <ng-template
          [ngTemplateOutlet]="tableCaptionTemplate"
          [ngTemplateOutletContext]="{
             genericSearchGroup: genericSearchGroup,
             genericSearchFormControlName: genericSearchFormControlName,
            }"
        >
        </ng-template>
      </div>

      <div class="mt-10">
        <app-generic-table

          [headers]="headers"
          [rowTemplate]="rowTemplate"

          [noDataMessage]="isFilterActive ? noDataMessage : noDataMessageOnFilter"
          [noDataTemplate]="noDataTemplate"
          [showTableIfEmpty]="showTableIfEmpty"

          [items$]="getListItems()"
          [isLoading$]="getListIsLoading()"

          [sortState]="genericSortingState"
          (sortChange)="applySortPatch($event)"

          [striped]="tableStriped"
          [rounded]="tableRounded"
          [bordered]="tableBordered"
          [flushStyles]="tableFlushStyles"
          [showRowBorder]="tableShowRowBorder"
          [rowHoverable]="tableRowHoverable"
        ></app-generic-table>
      </div>

      <app-paginator
        class="d-flex justify-content-between align-items-center flex-wrap"
        *ngIf="enablePagination"
        [paginatorState]="genericPaginatorState"
        (paginateChange)="applyPaginatorPatch($event)"
        [showLoading]="paginatorShowLoading"
        [showPageSize]="paginatorShowPageSize"
        [showPages]="paginatorShowPages"
        [showPageInfo]="paginatorShowPageInfo"
      ></app-paginator>

    </kt-card>
  `,
  styles: [``]
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class GenericTablePageComponent extends AbstractGenericListHandler<any> implements OnInit, OnDestroy {

  //general page
  @Input() title: string = 'Listado';

  @Input() showRefreshBtn: boolean = true;
  @Input() refreshBtnTitle: string = 'ACTION.RELOAD.GENERIC';
  @Input() showRefreshBtnIcon: boolean = true;
  @Input() refreshBtnIcon: string = 'fa fa-refresh';

  @Input() actionsTemplate: TemplateRef<any>;
  @Input() prependActions: boolean = false;

  @Input() tableCaptionTemplate: TemplateRef<any>;

  //generic table
  //general options
  @Input() rowTemplate: TemplateRef<any>;
  @Input() headers: IGenericTableHeader[];
  @Input() genericTableService: GenericTableService<any>;

  @Input() tableStriped: boolean = false;
  @Input() tableRounded: boolean = false;
  @Input() tableBordered: boolean = false;
  @Input() tableFlushStyles: boolean = false;
  @Input() tableShowRowBorder: boolean = false;
  @Input() tableRowHoverable: boolean = true;

  //no data options
  @Input() noDataMessage: string = 'Lo sentimos no hay información disponible.';
  @Input() noDataMessageOnFilter: string = 'No se encontraron elementos.';
  @Input() noDataTemplate: TemplateRef<any> | undefined = undefined;
  @Input() showTableIfEmpty: boolean = true;

  //pagination options
  @Input() enablePagination: boolean = true;
  @Input() paginatorShowLoading: boolean = true;
  @Input() paginatorShowPageSize: boolean = true;
  @Input() paginatorShowPages: boolean = true;
  @Input() paginatorShowPageInfo: boolean = true;

  //global search
  @Input() enableGlobalSearch: boolean = true;
  @Input() globalSearchPlaceholder: string = 'ACTION.SEARCH.GENERIC';
  @Input() globalSearchNgClass: string;
  @Input() globalSearchKeyUpDelay: number = 250;


  configureList(): IConfigureList {
    return {
      tableService: this.genericTableService,
      searchFilter: {
        enabled: this.enableGlobalSearch,
        inputDelay: this.globalSearchKeyUpDelay
      }
    };
  }

  ngOnInit(): void {
    this.initGenericListHandler();
  }

  ngOnDestroy(): void {
    this.destroyGenericListHandler();
  }

}
