import {inject, Injectable} from "@angular/core";
import {CONNECTION_STATUS_CONFIG} from "../../../../_config/connection-status";
import {fromEvent, merge, of, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ConnectionStatusService {

  //DI
  private toastrService: ToastrService = inject(ToastrService);
  private translateService: TranslateService = inject(TranslateService);

  networkStatus: boolean = false;
  networkStatusSubscription: Subscription = Subscription.EMPTY;

  checkNetworkStatus() {

    if (CONNECTION_STATUS_CONFIG.enabled) {

      let showStatusOnFirstLoad = CONNECTION_STATUS_CONFIG.showStatusOnFirstLoad || false;
      let showSuccessStatusOnFirstLoad = CONNECTION_STATUS_CONFIG.showSuccessStatusOnFirstLoad || false;

      let isFirstLoad = true;
      this.networkStatus = navigator.onLine;

      const onlineTitle = this.translateService.instant('TEXT.ONLINE_TITLE');
      const onlineMessage = this.translateService.instant('TEXT.ONLINE_MESSAGE');

      const offlineTitle = this.translateService.instant('TEXT.OFFLINE_TITLE');
      const offlineMessage = this.translateService.instant('TEXT.OFFLINE_MESSAGE');

      this.networkStatusSubscription = merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      )
        .pipe(map(() => navigator.onLine))
        .subscribe(status => {

          this.networkStatus = status;
          if (showStatusOnFirstLoad && !this.networkStatus) {
            this.toastrService.show(offlineMessage, offlineTitle, {toastClass: 'ngx-toastr connection-warning'});
          }

          if (
            (isFirstLoad && showSuccessStatusOnFirstLoad && showStatusOnFirstLoad && this.networkStatus)
            || !isFirstLoad && this.networkStatus) {
            this.toastrService.show(onlineMessage, onlineTitle, {toastClass: 'ngx-toastr connection-success'});
          }

          isFirstLoad = false;

        });

    }


  }

}
