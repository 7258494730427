/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface IGender {
  value: string | number,
  name: string
}

export const AVAILABLE_GENDERS: IGender[] = [
  {
    value: 'F',
    name: 'GENDER.FEMALE',
  },
  {
    value: 'M',
    name: 'GENDER.MALE',
  },
  {
    value: 'NB',
    name: 'GENDER.NON_BINARY',
  },
  {
    value: 'US',
    name: 'GENDER.UNSPECIFIED',
  }
]
