import {gql} from 'apollo-angular';


export const FRAGMENTS_USER_MANAGEMENT = {
  user: {
        username: gql`fragment username on User {
            id
            username
            name
        }`,
        fullName: gql`fragment name on User {
            id
            name
            lastname
        }`,
        patient: gql`fragment patient on User {
            id
            name
            email
            lastname
            gender
        }`,
        adminUser: gql`fragment adminUser on User {
            id
            username
            name
            lastname
            gender
            mobile
            email
            locale
            groups{
                collection{
                    ...group
                }
            }
        }`,
        userAll: gql`fragment userAll on User {
            id
            username
            name
            lastname
            mobile
            email
            roles
            enabled
            locale
            birthdate
            gender
            dni
            confirmationToken
            groups{
                collection{
                    ...group
                }
            }
        }`,
    },
  patient: {
    patientAll: gql`fragment patientAll on Patient {
      id
      username
      name
      lastname
      mobile
      email
      roles
      enabled
      locale
      birthdate
      gender
      dni
      groups{
        collection{
          ...group
        }
      }
    }`,
  },
  group: {
        group: gql`fragment group on Group {
            id
            name
            active
            roles
        }`
    },
};
