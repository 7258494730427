import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Provider} from '@angular/core';
import {HttpConfigInterceptorInterceptor} from '../../../interceptor/http-config-interceptor.interceptor';

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export const HttpInterceptorsProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpConfigInterceptorInterceptor,
  multi: true
};
