/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface IRole {
  role: string,
  name: string
}

export const ROLE_PATIENT: IRole = {name: 'Paciente', role: 'ROLE_PATIENT'};
export const ROLE_ADMIN: IRole = {name: 'Administrador', role: 'ROLE_ADMIN'};

export const AVAILABLE_ROLES: IRole[] = [
  ROLE_PATIENT,
  ROLE_ADMIN,
]

/**
 * Returns an array of all roles by the specified key.
 * @param {string} key - The key to use for retrieving roles. Can be either 'role' or 'name'.
 * @returns {Array} An array of all roles by the specified key.
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export function GET_ALL_ROLES_BY_KEY(key: 'role' | 'name' = 'role') {
  return AVAILABLE_ROLES.map(role => role[key]);
}
