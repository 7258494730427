import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListComponent} from "./component/list/list.component";
import {UserManagementComponent} from "./user-management.component";
import {AccessGuardCanMatchFn} from '../auth/services/access.guard';

const routes: Routes = [
  {
    path: 'user',
    component: UserManagementComponent,
    canMatch: [AccessGuardCanMatchFn],
    children: [
      {
        path: 'list',
        component: ListComponent,
      },
      {path: '', redirectTo: 'list', pathMatch: 'full'},
      {path: '**', redirectTo: 'list', pathMatch: 'full'},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
