import {inject, Injectable, Pipe, PipeTransform} from '@angular/core';
import {AVAILABLE_GENDERS} from "../../../../_config/genders";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'authGenderConverter',
  standalone: true
})
@Injectable({
  providedIn: 'root',
})
/**
 * AuthGenderConverterPipe converts gender values from their codes to their corresponding names.
 *
 * It also uses the TranslateService to translate the gender names to the user's preferred language. The pipe takes
 * in an array of gender codes or a single gender code and returns an array of gender names or a single gender name,
 * respectively.
 *
 * The pipe also allows for the option to join the values with a delimiter.
 *
 * In summary, this class is used to convert gender codes to human-readable genders names.
 */
export class AuthGenderConverterPipe implements PipeTransform {

  private translator = inject(TranslateService);

  transform(values: string | string[] | any[], joinValues: boolean = false, delimiter: string = ', '): string | string[] {

    if (!Array.isArray(values)) {
      return this.transformGenderValue(values)
    }

    values = values.map((role: string) => this.transformGenderValue(role));
    return joinValues ? values.join(delimiter) : values;
  }

  private transformGenderValue(value: string): string {
    let genderExist = AVAILABLE_GENDERS.filter(gender => (gender.value) === value)

    if (genderExist.length > 0) {
      value = genderExist[0].name;
    }

    return this.translator.instant(value);
  }
}
