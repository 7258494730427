import {Traceable} from "./traceable";
import {BaseGroup} from "./base-group";
import {TypeName} from "./type-name";
import {AbstractBaseEntity} from "./abstract-base-entity";

export class BaseUser extends AbstractBaseEntity implements Traceable, TypeName {
  username: string;
  name: string;
  lastname: string;
  email: string;
  password: string;
  groups: BaseGroup[];
  roles: string[] = [];
  lastLogin: number;
  mobile: number | string;
  locale: string = 'es';
  enabled: boolean = false;

  updateFrom(_instance: unknown): void {
    const user = _instance as any;

    this.id = user.id;
    this.username = user.username;
    this.password = user.password;
    this.name = user.name;
    this.email = user.email;
    this.roles = user.roles;
    this.mobile = user.mobile;
    this.locale = user.locale;
    this.enabled = user.enabled;
    this.groups = user.groups.collection;
    this.lastname = user.lastname

  }
}
