import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js/core';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  cryptoJS = require('crypto-js');
  ENCRYPTION_KEY = `${environment.encryptionKey}`;

  constructor() {
  }

  /**
   * @var integer Return encrypt method or Cipher method number. (128, 192, 256)
   */
  get encryptMethodLength() {
    const encryptMethod = this.encryptMethod;
    let aesNumber = '';
    // get only number from string.
    // @link https://stackoverflow.com/a/10003709/128761 Reference.
    const numberArr = encryptMethod.match(/\d+/);
    if (numberArr) {
      aesNumber = numberArr[0];
    }
    return parseInt(aesNumber, 10);
  }// encryptMethodLength


  /**
   * @var integer Return cipher method divide by 8. example: AES number 256 will be 256/8 = 32.
   */
  get encryptKeySize() {
    const aesNumber = this.encryptMethodLength;
    return parseInt(String(aesNumber / 8), 10);
  }// encryptKeySize


  /**
   * @link http://php.net/manual/en/function.openssl-get-cipher-methods.php Refer to available methods in PHP if we are working between
   * JS & PHP encryption.
   * @var string Cipher method.
   *              Recommended AES-128-CBC, AES-192-CBC, AES-256-CBC
   *              due to there is no `openssl_cipher_iv_length()` function in JavaScript
   *              and all of these methods are known as 16 in iv_length.
   */
  get encryptMethod() {
    return 'AES-256-CBC';
  }// encryptMethod


  /**
   * Decrypt string.
   *
   * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
   * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
   * @return string Return decrypted string.
   */
  decrypt(encryptedString:any) {
    const json = JSON.parse(this.cryptoJS.enc.Utf8.stringify(this.cryptoJS.enc.Base64.parse(encryptedString)));

    const salt = this.cryptoJS.enc.Hex.parse(json.salt);
    const iv = this.cryptoJS.enc.Hex.parse(json.iv);

    const encrypted = json.ciphertext; // no need to base64 decode.

    let iterations = parseInt(json.iterations, 10);
    if (iterations <= 0) {
      iterations = 999;
    }
    const encryptMethodLength = (this.encryptMethodLength / 4); // example: AES number is 256 / 4 = 64
    const hashKey = this.cryptoJS.PBKDF2(this.ENCRYPTION_KEY, salt, {
      hasher: this.cryptoJS.algo.SHA512,
      keySize: (encryptMethodLength / 8),
      iterations
    });

    const decrypted = this.cryptoJS.AES.decrypt(encrypted, hashKey, {mode: this.cryptoJS.mode.CBC, iv});

    return decrypted.toString(this.cryptoJS.enc.Utf8);
  }// decrypt


  /**
   * Encrypt string.
   *
   * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
   * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
   * @param string string The original string to be encrypt.
   * @param string key The key.
   * @return string Return encrypted string.
   */
  // tslint:disable-next-line:variable-name
  encrypt(string:any) {
    const iv = this.cryptoJS.lib.WordArray.random(16); // the reason to be 16, please read on `encryptMethod` property.

    const salt = this.cryptoJS.lib.WordArray.random(256);
    const iterations = 999;
    const encryptMethodLength = (this.encryptMethodLength / 4); // example: AES number is 256 / 4 = 64
    const hashKey = this.cryptoJS.PBKDF2(this.ENCRYPTION_KEY, salt, {
      hasher: this.cryptoJS.algo.SHA512,
      keySize: (encryptMethodLength / 8),
      iterations
    });

    const encrypted = this.cryptoJS.AES.encrypt(string, hashKey, {mode: this.cryptoJS.mode.CBC, iv});
    const encryptedString = this.cryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    const output = {
      ciphertext: encryptedString,
      iv: this.cryptoJS.enc.Hex.stringify(iv),
      salt: this.cryptoJS.enc.Hex.stringify(salt),
      iterations
    };

    return this.cryptoJS.enc.Base64.stringify(this.cryptoJS.enc.Utf8.parse(JSON.stringify(output)));
  }// encrypt
}
