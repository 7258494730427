import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AuthService} from "../../auth";

@Directive({
  selector: '[isGranted]'
})
export class IsGrantedDirective implements OnInit {

  @Input() grantedRoles?: string[];
  @Input() ifNotGranted?: 'remove'|'hide' = 'hide';

  constructor(private authService: AuthService, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (undefined !== this.grantedRoles && !this.grantAccess()) {
      'hide' === this.ifNotGranted ? this.elementRef.nativeElement.style.display = 'none' : this.elementRef.nativeElement.remove()
    }
  }

  grantAccess() {
    // @ts-ignore
    return this.authService.isAuthorized(this.grantedRoles);

  }

}
