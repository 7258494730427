import {Component} from '@angular/core';
import {UserService} from "../../services/user.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlertService} from "../../../shared/services/sweet-alert.service";
import {FormComponent} from "../form/form.component";
import {User} from "../../model/user";
import {ApolloHelperService} from "../../../shared/services/apollo-helper.service";
import {IGenericTableHeader} from "../../../shared/modules/crud-table/components/generic-table/generic-table.component";
import {noop} from "rxjs";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  headers: IGenericTableHeader[] = [
    {
      label: '#',
      sort: {
        column: 'id',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.NAME',
      sort: {
        column: 'name',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.USERNAME',
      sort: {
        column: 'username',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.EMAIL',
      sort: {
        column: 'email',
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.GENDER',
      sort: {
        column: 'gender',
        enabled: false
      }
    },
    {
      label: 'USER_MANAGEMENT.FIELD.ENABLED',
      sort: {
        column: 'enabled',
      }
    },
    {
      label: 'ACTION.PLURAL',
      class: 'text-end',
    },
  ];

  constructor(
    public userService: UserService,
    private modalService: NgbModal,
    private alertService: SweetAlertService,
  ) {
    this.userService.tableSearchableFields = [{orWhereFilter: ['name', 'lastname', 'username', 'email']}]
  }


  /**
   * Method to remove a user from the list
   *
   * @param id
   */
  async delete(id: string): Promise<void> {
    let confirm = await this.alertService.confirm().catch(reason => reason);

    if (confirm.isConfirmed) {

      let deleteResponse = await this.userService.deleteInstance(id)
        .catch(async reason => {
          const error = ApolloHelperService.getProcessedErrorFromResponse(reason)
          if (error) {
            await this.alertService.error({
              title: error.category,
              text: error.message,
            })
          }
        })

      if (undefined !== deleteResponse) {
        this.alertService.success().then()
        await this.userService.refreshApolloQuery()
      }
    }
  }

  async form(id?: string) {
    const modalRef = await this.modalService.open(FormComponent, {size: 'lg'});
    modalRef.componentInstance.genericEntityId = id;
    modalRef.result.then(async () => {
      await this.userService.refreshApolloQuery();
    })
      .catch(noop);
  }

  public async toggleUser(user: User, event: Event) {
    let switchInput = (event.target as HTMLInputElement);
    const currentValue = switchInput.checked;
    await this.userService
      .toggleState(user.id, currentValue)
      .catch(async reason => {
        console.error('toggleUser', reason)
        switchInput.checked = !currentValue;
        await this.alertService.error()
      })

    ;
  }


}
