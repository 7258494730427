<!-- start: Paginator -->
<div class="d-flex align-items-center py-3">
  <!-- begin: loading -->
  <div class="d-flex align-items-center" *ngIf="showLoading && isLoading">
    <div class="mr-2 text-muted">{{ "PAGINATOR.LOADING" | translate }}...</div>
    <div class="spinner spinner-primary mr-10"></div>
  </div>
  <!-- end: loading -->

  <!-- begin: pageSize -->
  <select
    *ngIf="showPageSize"
    class="form-select form-select-solid form-select-sm"
    style="width: 75px"
    [(ngModel)]="paginatorState.itemsPerPage"
    (change)="sizeChange()"
  >
    <option class="btn" *ngFor="let ps of paginatorState.pageSizes">
      {{ ps }}
    </option></select
  >
  <span
    *ngIf="showPageInfo"
    class="bootstrap-table-pagination pagination-total"
>&nbsp;{{ "PAGINATOR.SHOWING_ROWS" | translate }} {{ paginatorState.startRowIndex }} {{ "PAGINATOR.TO" | translate }}&nbsp;{{
  paginatorState.endRowIndex
  }}
  {{ "PAGINATOR.OF" | translate }}&nbsp;{{ paginatorState.totalCount }}</span
>
  <!-- end: pageSize -->
</div>
<div class="d-flex flex-wrap py-2 mr-3">
  <ng-pagination
    *ngIf="showPages"
    [collectionSize]="paginatorState.totalCount"
    [(page)]="paginatorState.page"
    [maxSize]="4"
    [rotate]="true"
    [boundaryLinks]="true"
    [pageSize]="paginatorState.itemsPerPage"
    (pageChange)="pageChange($event)"
  ></ng-pagination>
</div>
<!-- end: Paginator -->
