//app vocabs
import {locale as EN_VOCABS} from "../app/modules/i18n/vocabs/en";
import {locale as ES_VOCABS} from "../app/modules/i18n/vocabs/es";

//angular internal locales
import locale_es from '@angular/common/locales/es';
import locale_en from '@angular/common/locales/en';
import locale_es_cat from '@angular/common/locales/ca-ES-valencia';
import {registerLocaleData} from '@angular/common';

//plugins
import flatpickr from "flatpickr";
import {english} from "flatpickr/dist/l10n/default";
import {Catalan} from "flatpickr/dist/l10n/cat";
import {Spanish} from "flatpickr/dist/l10n/es";

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface ILangVocab {
  lang: string,
  data: object
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface LanguageFlag {
  lang: string;
  name: string;
  flag?: string;
  active?: boolean;
}

/**
 * This global constant allows you to configure which of the vocabs defined in i18n module will we load in our default translator.
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export const I18N_AVAILABLE_VOCABS: ILangVocab[] = [EN_VOCABS, ES_VOCABS]


/**
 * This global constant allows you to configure which languages appear in the app's language selector.
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export const AVAILABLE_LANGUAGES: LanguageFlag[] = [
  {
    lang: 'en',
    name: 'English',
    flag: './assets/media/flags/united-states.svg',
  },
  {
    lang: 'es',
    name: 'Español',
    flag: './assets/media/flags/spain.svg',
  },
  {
    lang: 'cat',
    name: 'Català',
    flag: './assets/media/flags/spain.svg',
  },
  // {
  //   lang: 'zh',
  //   name: 'Mandarin',
  //   flag: './assets/media/flags/china.svg',
  // },
  // {
  //   lang: 'ja',
  //   name: 'Japanese',
  //   flag: './assets/media/flags/japan.svg',
  // },
  // {
  //   lang: 'de',
  //   name: 'German',
  //   flag: './assets/media/flags/germany.svg',
  // },
  // {
  //   lang: 'fr',
  //   name: 'French',
  //   flag: './assets/media/flags/france.svg',
  // },
];


/**
 * This function is responsible for initializing plugins based on the selected locale. It takes in a localeID parameter
 * and uses a switch statement to determine which locale-specific plugins to register.
 * @param localeID
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export function onLocaleChangeInitPlugins(localeID: string): void {
  switch (localeID) {
    case 'en':
      registerLocaleData(locale_en, 'en');
      flatpickr.localize(english);
      break
    case 'cat':
      registerLocaleData(locale_es_cat, 'cat');
      flatpickr.localize(Catalan);
      break
    case 'es':
    default:
      registerLocaleData(locale_es, 'es');
      flatpickr.localize(Spanish);
      break
  }
}
