export const I18N_ACTIONS_EN = {
  ACTION: {
    SINGULAR: 'Actions',
    PLURAL: 'Actions',
    ADD: {GENERIC: 'Add'},
    RELOAD: {GENERIC: 'Reload'},
    SEARCH: {GENERIC: 'Search'},
    SAVE: {GENERIC: 'Save', AND_CONTINUE: 'Save and continue'},
    EDIT: {GENERIC: 'Editar'},
    DISCARD: {GENERIC: 'Reset'},
    CANCEL: {GENERIC: 'Cancel'},
    SEND: {GENERIC: 'Send', EMAIL: 'Send email'},
    CLOSE: {GENERIC: 'Close'},
    AGREE: {GENERIC: 'Accept', IAGREE: 'I agree'},
    CONFIRM: {GENERIC: 'Ok I have it!', YES: 'Yes, sure', DELETED: 'Yes, delete!', ACCOUNT: 'Confirm account'},
    APPLY: {GENERIC: 'Apply'},
    ACCESS: {GENERIC: 'Log in'},
    UPDATE: {GENERIC: 'Update'},
    REGISTER: {GENERIC: 'Sign in'},
    CONTINUE: {GENERIC: 'Continue'},
    FINALIZE: {GENERIC: 'Finalize'},
    GO_BACK: {GENERIC: 'Go back', LIST: 'Return to the list'},
    NEW: {GENERIC: 'New'},
    EVALUATE: {GENERIC: 'Evaluate'},
    GO: {GENERIC: 'Go'},
    REMOVE: {GENERIC: 'Delete'},
    UPLOAD: {GENERIC: 'Upload'},
    FILTER: {GENERIC: 'Filter'},
    IMPORT: {GENERIC: 'Import', DATA: 'import data'},
  }
}

export const I18_VALIDATIONS_RULES_EN = {
  VALIDATION: {
    REQUIRED: 'Required.',
    MAX_LENGTH: 'This value is too long. You should have {{ max }} characters or less.',
    DATE: 'Invalid date.',
    DATETIME: 'This value is not a valid date and time.',
    EMAIL: 'Invalid email.',
    PASSWORD_MATCH: 'Passwords do not match.',
    PASSWORD_STRENGTH: 'The password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and no fewer than {{ max }} characters.',
  }
}


export const I18N_TIME_PERIODICITY_EN = {
  PERIODICITY: {
    NONE: 'None',
    TODAY: 'Today',
    IN: 'In',
    AGO: 'Ago',
    SINCE: 'Since',
    EVERY_WEEK: 'SemanalmenteWeekly',
    EVERY_DAY: 'Daily',
    EVERY_CERTAIN_DAYS: 'Every {{value}} days',
    EVERY_CERTAIN_WEEKS: 'Every {{value}} weeks',
    EVERY_MONTH: 'Monthly',
    EVERY_QUARTER: 'Every quarter',
    EVERY_SEMESTER: 'Each semester',
    EVERY_CERTAIN_MONTHS: 'Every {{value}} months',
    EVERY_YEAR: 'Yearly',
    EVERY_CERTAIN_YEARS: 'Every {{value}} years',
    YEARS: 'Years',
    YEAR: 'Year',
    MONTHS: 'Months',
    MONTH: 'Month',
    DAYS: 'Days',
    DAY: 'Day',
    WEEKS: 'Weeks',
    WEEK: 'Week',
    HOURS: 'Hours',
    HOUR: 'Hour',
    MINUTES: 'Minutes',
    MINUTE: 'Minute',
    SECONDS: 'Seconds',
    SECOND: 'Second',
  },
}


export const I18N_PARTIALS_EN = {
  ...I18N_ACTIONS_EN,
  ...I18_VALIDATIONS_RULES_EN,
  ...I18N_TIME_PERIODICITY_EN
}
