import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserManagementRoutingModule} from './user-management-routing.module';
import {ListComponent} from './component/list/list.component';
import {FormComponent} from './component/form/form.component';
import {UserManagementComponent} from "./user-management.component";
import {SharedModule} from "../shared/shared.module";
import {CRUDTableModule} from "../shared/modules/crud-table";
import {AuthIsCurrentUserIdPipe} from "../auth/pipe/auth-is-current-user-id.pipe";
import {AuthGenderConverterPipe} from "../auth/pipe/auth-gender-converter.pipe";
import {FlatpickrModule} from "angularx-flatpickr";
import {AuthRoleConverterPipe} from "../auth/pipe/auth-role-converter.pipe";
import {NgSelectModule} from "@ng-select/ng-select";
import {PasswordSecurityAdvanceDirective} from "../shared/directives/password-security-advance.directive";

@NgModule({
  declarations: [
    ListComponent,
    FormComponent,
    UserManagementComponent,
  ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    SharedModule,
    CRUDTableModule,
    AuthIsCurrentUserIdPipe,
    AuthGenderConverterPipe,
    FlatpickrModule,
    AuthRoleConverterPipe,
    NgSelectModule,
    PasswordSecurityAdvanceDirective,
  ]
})
export class UserManagementModule {
}
