<ng-container *ngIf="isActive">
  <ng-container *ngIf="activeDirection === 'asc'">
    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr065.svg'"
      [cacheSVG]="true"
      class="svg-icon svg-icon-3 ms-1 cursor-pointer">
    </span>
  </ng-container>
  <ng-container *ngIf="activeDirection === 'desc'">
    <span
      [cacheSVG]="true"
      [inlineSVG]="'./assets/media/icons/duotune/arrows/arr066.svg'"
      class="svg-icon svg-icon-3 ms-1 cursor-pointer"
    ></span>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isActive">
  <span
    [cacheSVG]="true"
    [inlineSVG]="'./assets/media/icons/duotune/arrows/arr082.svg'"
    class="svg-icon svg-icon-3 ms-1 cursor-pointer"
  ></span>
</ng-container>
