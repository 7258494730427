import {Component, HostBinding, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {AuthService, UserType} from "../../../../../modules/auth";
import {LocaleService} from "../../../../../modules/i18n/service/locale.service";
import {AVAILABLE_LANGUAGES, LanguageFlag} from "../../../../../../_config/languages";




@Component({
  selector: 'app-user-inner',
  templateUrl: './user-inner.component.html',
})
export class UserInnerComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  language: LanguageFlag;
  langs = AVAILABLE_LANGUAGES;

  userData: WritableSignal<UserType> = signal(undefined);


  constructor(
    private auth: AuthService,
    private localeService: LocaleService
  ) {
  }

  ngOnInit(): void {
    this.userData = this.auth.currentUser;
    this.initDropdownLanguage( this.localeService.currentLocale);
  }

  logout() {
    this.auth.logout();
    // document.location.reload();
  }

  onSelectLanguage(lang: string) {
    this.localeService.setLocale(lang);
    this.initDropdownLanguage(lang);
  }

  initDropdownLanguage(lang: string) {
    this.langs.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
  }

  ngOnDestroy() {
  }
}
