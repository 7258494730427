import {inject, Injectable, Pipe, PipeTransform} from '@angular/core';
import {AVAILABLE_ROLES} from "../../../../_config/roles";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'authRoleConverter',
  standalone: true
})
@Injectable({
  providedIn: 'root',
})
/**
 * AuthRoleConverterPipe is used to transform a string or array of strings representing roles into an array of human-readable role names.
 *
 * The transform method takes in a value parameter that can be a string, an array of strings. The method then maps over
 * the roles array and calls the private transformRolesName method for each role.
 *
 * In summary, this class is used to convert role names from internal names to human-readable names.
 */
export class AuthRoleConverterPipe implements PipeTransform {

  private translator = inject(TranslateService);

  transform(value: string | string[] | any[]): string[] {
    let roles = 'string' === typeof value ? [value] : value
    return roles.map((role: string) => this.transformRolesName(role, true));
  }

  private transformRolesName(name: string, isInternalName: boolean): string {
    let roleExist = AVAILABLE_ROLES.filter(role => (isInternalName ? role.role : role.name) === name)
    if (roleExist.length > 0) {
      name = isInternalName ? roleExist[0].name : roleExist[0].role;
    }

    return this.translator.instant(name);
  }
}
