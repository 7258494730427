import {Component, OnInit, WritableSignal} from '@angular/core';
import { LayoutService } from '../../core/layout.service';
import {AuthService, UserType} from "../../../../modules/auth";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  toolbarButtonMarginClass = 'ms-1 ms-lg-3';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft: string = 'menu';
  public userData: WritableSignal<any>;

  constructor(private layout: LayoutService, public auht: AuthService) {

  }

  ngOnInit(): void {
    this.userData = this.auht.currentUser;
    this.headerLeft = this.layout.getProp('header.left') as string;
  }
}
