import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanMatchFn,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';


/**
 * AccessGuardCanMatchFn
 * This function is used as a guard for checking if the current user has access to the requested route.
 * It checks if the user has access to the route path by calling the userHasAccessToRoute() function from the AuthService.
 * The result of this function will determine if the user is allowed to navigate to the route or not.
 *
 *
 * @param route
 * @param segments
 * @constructor
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export const AccessGuardCanMatchFn: CanMatchFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authService = inject(AuthService);
  return authService.userHasAccessToRoute(route.path);
};

/**
 *
 * @param childRoute
 * @param state
 * @constructor
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 *
 */
export const AccessGuardCanActivateChildFn: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot,
                                                                  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authService = inject(AuthService);
  return authService.userHasAccessToRoute(childRoute.root);
};
