import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {ApolloFormError} from './apollo-helper.service';

export class FormTools {

  public static isControlValid(control: AbstractControl<any, any>|FormControl|null): boolean {

    if (null === control) {
      throw new Error('isControlValid::control cannot be null.')
    }

    return control.valid && (control.dirty || control.touched);
  }

  public static isControlInvalid(control: AbstractControl<any, any>|FormControl|null): boolean {

    if (null === control) {
      throw new Error('isControlInvalid::control cannot be null.')
    }

    return control.invalid && (control.dirty || control.touched);
  }

  public static controlHasError(control: AbstractControl<any, any>|FormControl|null, validation: string): boolean {

    if (null === control) {
      throw new Error('controlHasError::control cannot be null.')
    }

    return control?.hasError(validation) && (control?.dirty || control?.touched);
  }

  public static isControlTouched(control: AbstractControl<any, any>|FormControl|null): boolean {

    if (null === control) {
      throw new Error('isControlTouched::control cannot be null.')
    }

    return control.dirty || control.touched;
  }

  /**
   * Update the values of a FromGroup based on the response from the server
   * takes the keys of an object and checks that a control with the same name exists
   * if exist update its value with the response value for that key
   *
   * @param form
   * @param response
   */
  public static patchFormValuesFromResponse(form: FormGroup, response: { [key: string]: any }): void {
    Object.keys(response).forEach(key => {
      if (form.controls[key]) {
        form.controls[key].patchValue(response[key]);
      }
    });
  }

  /**
   * Allows assigning an error to a FormControl, even if the rule does not exist in the established validations
   *
   * @param formGroup
   * @param field
   * @param rule
   * @param errorMessage
   */
  public static setFormControlError(formGroup: FormGroup, field: string, rule: string, errorMessage: string) {
    let formControl = formGroup.get(field);
    if (formControl) {
      let validation: Record<any, any> = {}
      validation[rule] = errorMessage
      formControl.setErrors(validation)
    }
  }

  /**
   * Allows you to add errors to controls within a FormGroup,
   * coming from the server
   *
   *
   * @param {FormGroup} formGroup
   * @param {ApolloFormError} errors
   * @param {string} propagateErrorAs
   */
  public static addFormGroupErrorsFromMutationResponse(formGroup: FormGroup, errors: ApolloFormError, propagateErrorAs: string) {
    errors.forEach(error => {
      error.extensions?.violations?.forEach(({path, message}) => {
        FormTools.setFormControlError(formGroup, path, propagateErrorAs, message)
      })
    })
  }


  /**
   * gets only the dirty(changed) values from a form
   *
   */
  public static getDirtyValues(form: any): any {
    const dirtyValues = {};

    Object.keys(form.controls)
      .forEach(key => {
        const currentControl = form.controls[key];

        if (currentControl.dirty) {
          if (currentControl.controls) {
            // @ts-ignore
            dirtyValues[key] = FormTools.getDirtyValues(currentControl);
          } else {
            // @ts-ignore
            dirtyValues[key] = currentControl.value;
          }
        }
      });

    return dirtyValues;
  }
}
