import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslationModule} from '../i18n';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {IsGrantedDirective} from './directives/is-granted.directive';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {NoDataTemplateComponent} from './components/no-data-template/no-data-template.component';
import {KtCardComponent} from './components/kt-card/kt-card.component';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgSelectModule} from "@ng-select/ng-select";


@NgModule({
  declarations: [
    IsGrantedDirective,
    NoDataTemplateComponent,
    KtCardComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgSelectModule,
    TranslationModule,
    InlineSVGModule,
    NgxUiLoaderModule
  ],
  exports: [
    CommonModule,
    NgbModule,
    TranslationModule,
    InlineSVGModule,
    IsGrantedDirective,
    NoDataTemplateComponent,
    NgxUiLoaderModule,
    KtCardComponent,
  ]
})
export class SharedModule {
}
