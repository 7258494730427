import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { NgxUiLoaderService, NgxUiLoaderConfig } from 'ngx-ui-loader';

export const NGX_UI_LOADER_CONFIG_DEFAULT: NgxUiLoaderConfig = {
  bgsColor: '#0095E8',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'folding-cube',
  blur: 60,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#0095E8',
  fgsPosition: 'center-center',
  fgsSize: 50,
  fgsType: 'ball-spin-fade-rotating',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(146,213,251,0.12)',
  pbColor: '#0095E8',
  pbDirection: 'ltr',
  pbThickness: 4,
  hasProgressBar: false,
  textColor: '#000000',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
  text: ''
};

@Injectable({
  providedIn: 'root'
})
export class CustomLoaderService {
  // tslint:disable-next-line:variable-name
  private _config: BehaviorSubject<NgxUiLoaderConfig>;
  config: Observable<NgxUiLoaderConfig>;

  constructor(public ngxUiLoaderService: NgxUiLoaderService) {

    this._config = new BehaviorSubject<NgxUiLoaderConfig>(NGX_UI_LOADER_CONFIG_DEFAULT);
    this.config = this._config.asObservable();
  }

  start(config: any) {
    this._config.next(Object.assign(this._config.value, config));
    this.ngxUiLoaderService.start();
  }
  startLoader(id: string, config?: any) {
    this._config.next(Object.assign(this._config.value, config));
    this.ngxUiLoaderService.startLoader(id);
  }


  stop() {
    this.ngxUiLoaderService.stop();
  }
  stopLoader(id: string) {
    this.ngxUiLoaderService.stopLoader(id);
  }

  stopAll(){
    this.ngxUiLoaderService.stopAll();

  }

}
