import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../modules/auth';
import {catchError} from 'rxjs/operators';
@Injectable()
export class HttpConfigInterceptorInterceptor implements HttpInterceptor {

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authToken = AuthService.getAuthFromLocalStorage();

    if (null !== authToken) {
      request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + authToken)
        });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        console.log('err', error);
        return throwError(error);
      })
    );
  }
}
