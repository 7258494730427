import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ASIDE_CONFIG} from "../../../../../../_config/aside-menu-config";

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export interface AsideDynamicItemConfigType {
  title?: string
  route?: string
  submenu?: AsideDynamicItemConfigType[]
  icon?: string
  svg?: string

  pageTitle?: string

  roles?: string[]
  isNotGrantedAction?: 'remove' | 'hide'

  header?: string
  headerRoles?: string[]
  headerClass?: string[] | string
  headerStyles?: { [klass: string]: any; }

  class?: string[] | string
  styles?: {[key: string]: any}
}


@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit, OnDestroy{

  public asideConfig: AsideDynamicItemConfigType[] = ASIDE_CONFIG

  public currentUrl: string;
  private subscriptions: Subscription[] = [];

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    this.currentUrl = this.router.url.split(/[?#]/)[0];

    const routerSubscr = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentUrl = event.url;
    });
    this.subscriptions.push(routerSubscr);

  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  isSubItemActivated (items: AsideDynamicItemConfigType[]): boolean {
   return items.filter(item => undefined !== item.route && this.isMenuItemActive(item.route)).length > 0
  }

  isMenuItemActive(path: string) {
    if (!this.currentUrl || !path) {
      return false;
    }

    if (this.currentUrl === path) {
      return true;
    }

    return this.currentUrl.indexOf(path) > -1;
  }
}
