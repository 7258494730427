import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './modules/auth';
import {GraphQLModule} from './graphql.module';
import {NgxUiLoaderModule, NgxUiLoaderRouterModule} from 'ngx-ui-loader';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {NGX_UI_LOADER_CONFIG_DEFAULT} from './modules/shared/services/custom-loader.service';

import {HttpInterceptorsProvider} from './modules/shared/provider/interceptor.provider';
import {LocaleProvider} from './modules/shared/provider/locale.provider';

import {provideEnvironmentNgxMask} from 'ngx-mask';
import {ToastrModule} from 'ngx-toastr';
import {FlatpickrDefaults, FlatpickrModule} from "angularx-flatpickr";
import {NgSelectModule} from "@ng-select/ng-select";

const LOADER_UI_CONFIG = NGX_UI_LOADER_CONFIG_DEFAULT;
LOADER_UI_CONFIG.bgsColor = '#24d2ff'
LOADER_UI_CONFIG.fgsColor = '#24d2ff'
LOADER_UI_CONFIG.pbColor = '#24d2ff'
LOADER_UI_CONFIG.overlayColor = 'rgba(231,250,252,0.27)'

const FLATPICKER_CONFIG = new FlatpickrDefaults();
FLATPICKER_CONFIG.monthSelectorType = 'dropdown'
FLATPICKER_CONFIG.time24hr = true
FLATPICKER_CONFIG.altInput = true
FLATPICKER_CONFIG.altFormat = "d/m/Y"
FLATPICKER_CONFIG.dateFormat = "Y-m-d"
FLATPICKER_CONFIG.convertModelValue = true

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe({
        next: (data) => {
          resolve(data);
        },
        error: () => {
          authService.refreshToken()
            .catch(reason => {
              console.log('refreshToken -> error', reason);
              authService.logout();
            })
            .finally(() => {
              window.location.reload();
            })
        }
      })
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    NgbModule,
    GraphQLModule,
    TranslateModule.forRoot(),
    NgxUiLoaderRouterModule,
    NgxUiLoaderModule.forRoot(LOADER_UI_CONFIG),
    InlineSVGModule.forRoot(),
    ToastrModule.forRoot(),
    FlatpickrModule.forRoot(FLATPICKER_CONFIG),
    NgSelectModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    HttpInterceptorsProvider,
    LocaleProvider,
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
