import "reflect-metadata";

const nameKey = Symbol('name');

/**
 * To preserve class name though mangling.
 *
 * @example
 * @ClassName('Customer')
 * class Customer {}
 * @param value
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export function ClassName(value: string): ClassDecorator {
  return (Reflect as any).metadata(nameKey, value);
}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class DecoratorReflectMetadata {
  /**
   * @example
   * const type = Customer;
   * getClassName(type); // 'Customer'
   * @param type
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static getClassName(type: Function): string {
    return (Reflect as any).getMetadata(nameKey, type);
  }

  /**
   * @example
   * const instance = new Customer();
   * getInstanceName(instance); // 'Customer'
   * @param instance
   * @author Carlos Duardo <carlos.duardo@qualud.es>
   */
  public static getInstanceName(instance: Object): string {
    return (Reflect as any).getMetadata(nameKey, instance.constructor);
  }
}
