<ng-template #rowTemplate let-item="data" let-index="index">

  <td>{{ index }}</td>
  <td>{{ item.name }} {{ item.lastname }}</td>
  <td>{{ item.username }}</td>
  <td>{{ item.email }}</td>
  <td>{{ item.gender | authGenderConverter }}</td>
  <td>
    <label class="form-check form-switch form-check-custom form-check-solid">
      <input (change)="toggleUser(item, $event)"
             [checked]="item.enabled"
             [disabled]="item.id | isCurrentUserId: 'iri'"
             class="form-check-input h-30px w-50px"
             type="checkbox"
      />
    </label>
  </td>
  <td class="text-end">
    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1"
       (click)="form(item.id)">
      <i class="fa fa-pencil"></i>
    </a>
    <a class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm mx-1"
       *ngIf="!(item.id | isCurrentUserId: 'iri')"
       (click)="delete(item.id)">
      <i class="fa fa-trash "></i>
    </a>
  </td>

</ng-template>

<ng-template #btnTemplate>
  <a type="button" class="btn btn-primary ms-3" (click)="form()"> {{ 'ACTION.ADD.GENERIC'| translate }}
  </a>
</ng-template>

<app-generic-table-page
  [title]="'USER_MANAGEMENT.LIST.TITLE'"
  [actionsTemplate]="btnTemplate"

  [headers]="headers"
  [rowTemplate]="rowTemplate"
  [genericTableService]="userService"
></app-generic-table-page>
