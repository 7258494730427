import {Injectable, Optional, SkipSelf} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from "rxjs";
import {onLocaleChangeInitPlugins} from "../../../../_config/languages";

export interface Locale {
  lang: string;
  data: any;
}

export const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
  providedIn: 'root',
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class LocaleService {

  public onLangChangeSubscription: Subscription = Subscription.EMPTY;

  private initialized = false;
  private langIds: any = [];

  constructor(
    public translate: TranslateService,
    @Optional()
    @SkipSelf()
      otherInstance: LocaleService,
  ) {
    if (otherInstance) throw 'LocaleService should have only one instance.';
  }

  get currentLocale(): string {
    return (
      localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) ||
      this.translate.currentLang
    );
  }

  initLocale(localeID: string | null = null, defaultLocaleID = 'es') {
    if (this.initialized) return;

    if (null === localeID) {
      localeID = defaultLocaleID;
    }

    this.onLangChangeSubscription = this.translate.onLangChange.subscribe(event => {
      this.initPlugins(event.lang)
    })

    this.setDefaultLocale(defaultLocaleID);
    this.setLocale(localeID);

    this.initialized = true;
  }

  setDefaultLocale(localeID: string) {
    this.translate.setDefaultLang(localeID);
  }

  setLocale(localeID: string) {
    localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, localeID);
    this.translate.use(localeID);
  }

  loadLocalesTranslations(...args: Locale[]): void {
    [...args].forEach((locale) => {
      this.loadLocaleTranslation(locale);
    });

    this.translate.langs = [...new Set(this.translate.langs)];
  }

  loadLocaleTranslation(locale: Locale) {
    if (!this.translate.langs.includes(locale.lang)) {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);

      this.translate.langs.push(locale.lang)
    }
  }

  // lazyLoadLocaleTranslationFromLangID(localeID: string) {
  //   const vocabs = I18N_AVAILABLE_VOCABS.filter((vocab: ILangVocab) => vocab.lang === localeID);
  //   this.loadLocalesTranslations(...vocabs)
  // }

  private initPlugins(localeID: string) {
    onLocaleChangeInitPlugins(localeID);
  }


}
