import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {CustomLoaderService} from "../../services/custom-loader.service";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'kt-card',
  template: `

    <div
      *ngIf="undefined !== mainHeader"
      class="d-flex w-100 justify-content-start align-items-center pb-2">
      <label class="" [ngClass]="mainHeaderClass" [innerHTML]="mainHeader || '' | translate"></label>
    </div>

    <div
      class="card gutter-b"
      [class.card-flush]="!showDivider"
      [class.bg-transparent]="bgTransparent"
    >
      <ngx-ui-loader [loaderId]="componentLoaderID"></ngx-ui-loader>
      <div *ngIf="showCardHeader" class="card-header flex-wrap pt-2 pb-0"
      >
        <div class="card-title" [ngClass]="cardTitleClass">
          <h2 class="card-title align-items-start flex-column fw-bolder">
              <span class="card-label fw-bolder fs-3 pb-1">
                {{ cardTitle || '' | translate }}
             </span>
            <span *ngIf="undefined !== cardSubtitle" class="text-muted fw-bold fs-7">
                 {{ cardSubtitle || '' | translate }}
              </span>
          </h2>
        </div>
        <div class="card-toolbar">
          <ng-template
            [ngTemplateOutlet]="toolbarActionTemplate">
          </ng-template>
        </div>
      </div>
      <div class="card-body" [ngClass]="cardBodyClass">
        <ng-content></ng-content>
      </div>
      <div *ngIf="footerTemplate" class="card-footer">
        <ng-template
          [ngTemplateOutlet]="footerTemplate">
        </ng-template>
      </div>
    </div>
  `,
  styles: [`
    .shadow-sm {
      box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
    }

    .card {
      border: 0;
      box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    }


    .card .card-header {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      min-height: 70px;
      padding: 0 2.25rem;
      background-color: transparent;
      border-bottom: 1px solid #EFF2F5;
    }

    .card-header:first-child {
      border-radius: calc(0.475rem - 1px) calc(0.475rem - 1px) 0 0;
    }

    .card .card-header .card-title, .card .card-header .card-title .card-label {
      font-weight: 500;
      font-size: 1.275rem;
      color: #181C32;
    }

    .card .card-header .card-toolbar {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      flex-wrap: wrap;
    }

    .card.card-flush > .card-header {
      border-bottom: 0;
    }

    .card .card-body {
      padding: 1rem 1.25rem;
    }

    .card .card-footer {
      padding: 2rem 2.25rem;
      background-color: transparent;
      border-top: 1px solid #EFF2F5;
    }
  `]
})
/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class KtCardComponent implements OnInit, OnDestroy {

  @Input() cardTitle: string|null = 'Card Title';
  @Input() cardSubtitle?: string;
  @Input() cardTitleClass: string | string[] = '';
  @Input() cardBodyClass: string | string[] = '';
  @Input() toolbarActionTemplate: TemplateRef<any> | null = null;
  @Input() footerTemplate?: TemplateRef<any>;
  @Input() isBlocked$?: BehaviorSubject<boolean> | undefined;
  @Input() showDivider: boolean = false;
  @Input() bgTransparent: boolean = false;


  @Input() showCardHeader: boolean = true;

  @Input() mainHeader?: string;
  @Input() mainHeaderClass: string | string[] = '';

  componentLoaderID: string = 'kt-card-loaderId-' + self.crypto.randomUUID();
  subscription: Subscription;

  constructor(private loaderUi: CustomLoaderService,) {
  }

  ngOnInit(): void {
    if (this.isBlocked$) {
      this.subscription = this.isBlocked$.subscribe(value => {
        if (value) {
          this.blockUI()
          return;
        }

        this.unBlockUI()
      })
    }

  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  public blockUI() {
    this.loaderUi.startLoader(this.componentLoaderID)
    // this.blockUI.start();
  }

  public unBlockUI() {
    // this.blockUI.reset()
    this.loaderUi.stopLoader(this.componentLoaderID)
  }

}
