/**
 * This is a configuration object for connection status, which allows for enabling or disabling the display of status
 * messages.
 *
 * The "showStatusOnFirstLoad" property determines whether the status should be shown on the first load of the page.
 * The "showSuccessStatusOnFirstLoad" property determines whether a success status message should be
 * shown on the first load.
 *
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export const CONNECTION_STATUS_CONFIG = {
  enabled: true,
  showStatusOnFirstLoad: true,
  showSuccessStatusOnFirstLoad: false,
};
