import {LOCALE_ID, Provider} from '@angular/core';
import {LocaleService} from '../../i18n/service/locale.service';

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class LocaleID extends String {
  constructor(private localeService: LocaleService) {
    super();
  }
  toString(): string {
    return this.localeService.currentLocale;
  }
  valueOf(): string {
    return this.toString();
  }

}

/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleID,
  deps: [LocaleService],
};
