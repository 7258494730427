import {BaseUser} from "../../shared/model/base-user";
import {ClassName} from "../../shared/modules/crud-table/abstract/generic-form/decorator-reflect-metadata";

export type UserExtended =
  | User
  | Patient

@ClassName('User')
export class User extends BaseUser {
  gender: string
  declare mobile: string
  dni?: string = ''
  declare id: string
  avatar?: string
  birthdate: number | null = null

  updateFrom(_instance: unknown) {
    super.updateFrom(_instance);

    const user = _instance as User;

    this.birthdate = user.birthdate
    this.gender = user.gender
    this.dni = user.dni || ''
    this.roles = user.roles || ['ROLE_USER']
  }

  getFullName(): string {
    return `${this.name} ${this.lastname}`
  }
}

@ClassName('Patient')
export class Patient extends User {
}
