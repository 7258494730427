import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {HelperTools} from "../../shared/services/helperTools";

@Pipe({
  name: 'isCurrentUserId',
  standalone: true
})
@Injectable({
  providedIn: 'root',
})
/**
 * AuthIsCurrentUserIdPipe is used to check if the current user ID matches the provided value. The transform method
 * takes two parameters: value, which can be a string or a number, and valueFrom, which is a string that specifies whether
 * the value is an IRI (Internationalized Resource Identifier) that needs to be converted to an ID using a helper function
 * called iriToId.
 *
 * The method then retrieves the current user ID from local storage and compares it to the provided value. The method returns
 * a boolean indicating whether the IDs match or not. This pipe can be used in Angular templates to conditionally display
 * content based on the current user ID.
 */
export class AuthIsCurrentUserIdPipe implements PipeTransform {

  transform(value: string | number, valueFrom: 'iri' | 'id' = 'id' ): boolean {

    const currentUserId = Number(localStorage.getItem(AuthService.AUTH_USER_ID));
    value = 'iri' === valueFrom ? HelperTools.iriToId(String(value)) : Number(value)

    return currentUserId === value
  }
}
