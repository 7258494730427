import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from "./_metronic/layout";

import {DefaultLayoutConfig, ILayout} from "./_metronic/layout/core/default-layout.config";
import {LocaleService} from './modules/i18n/service/locale.service';
import {I18N_AVAILABLE_VOCABS} from "../_config/languages";
import {ConnectionStatusService} from "./modules/shared/services/connection-status.service";


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private layoutService: LayoutService,
    private localeService: LocaleService,
    private connectionStatusService: ConnectionStatusService,
  ) {

  }

  ngOnInit() {
    const layoutDefaultConfig: ILayout = DefaultLayoutConfig;
    layoutDefaultConfig.header.left = 'page-title'; //activar top page title | 'menu' activar top mega menu
    this.layoutService.setConfig(layoutDefaultConfig)

    // register translations
    this.localeService.loadLocalesTranslations(...I18N_AVAILABLE_VOCABS);
    // init locale
    this.localeService.initLocale();

    this.connectionStatusService.checkNetworkStatus();
  }

  ngOnDestroy() {
    this.connectionStatusService.networkStatusSubscription.unsubscribe();
    this.localeService.onLangChangeSubscription.unsubscribe();
  }


}
