import {ICrudLocale} from "../../i18n/interface";

export const GROUP_LOCALE: ICrudLocale = {
  GROUP_MANAGEMENT: {
    FIELD: {
      NAME: 'Nombre',
      ACTIVE: 'Activo',
      ROLES: 'Roles',
    },
    LABEL: {
      SINGULAR: 'Grupo',
      PLURAL: 'Grupos',
    },
    LIST: {
      TITLE: 'Listado de grupos'
    }
  }
}
