import {Routes} from '@angular/router';
import {AccessGuardCanMatchFn} from '../modules/auth/services/access.guard';

const Routing: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'profile',
    canMatch: [AccessGuardCanMatchFn],
    loadChildren: () =>
      import('../_metronic/layout/components/_partials/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'management',
    loadChildren: () =>
      import('../modules/user-management/user-management.module').then((m) => m.UserManagementModule),
  },
   {
    path: 'management',
    loadChildren: () =>
      import('../modules/group-management/group-management.module').then((m) => m.GroupManagementModule),
  },
  {
    path: 'management',
    loadChildren: () =>
      import('../modules/patient-management/patient-management.module').then((m) => m.PatientManagementModule),
  },
  {
    path: 'management',
    loadChildren: () =>
      import('../modules/device-management/device-management.module').then((m) => m.DeviceManagementModule),
  },
  {
    path: '',
    redirectTo: '/management/patient/list/admitted',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
