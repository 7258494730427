export const PageSizes = [3, 5, 10, 15, 50, 100];

export interface IGenericPaginatorState {
  page: number;
  itemsPerPage: number;
  totalCount: number;
  lastPage?: number;

  recalculatePaginator(
    paginationInfo: {
      itemsPerPage: number
      lastPage: number
      totalCount: number
    }): IGenericPaginatorState;
}

export class GenericPaginatorState implements IGenericPaginatorState {
  page = 1;
  itemsPerPage = PageSizes[2];
  totalCount = 0;
  pageSizes: number[] = PageSizes;
  lastPage? = 0;
  startRowIndex: number = 1;
  endRowIndex: number = this.itemsPerPage;

  recalculateStartAndEndIndex() {
    this.startRowIndex = this.totalCount > 0 ? ((this.page - 1) * this.itemsPerPage) + 1 : 0
    this.endRowIndex = Math.min(this.startRowIndex + this.itemsPerPage - 1, this.totalCount)
  }

  recalculatePaginator(
    paginationInfo: {
      itemsPerPage?: number
      lastPage?: number
      totalCount: number
    }
  ): GenericPaginatorState {

    this.totalCount = paginationInfo.totalCount;
    this.lastPage = paginationInfo.lastPage

    if (undefined !== paginationInfo.itemsPerPage) {
      this.itemsPerPage = paginationInfo.itemsPerPage;
    }


    this.recalculateStartAndEndIndex()
    return this;
  }
}

export interface IPaginatorView {
  paginator: GenericPaginatorState;

  ngOnInit(): void;

  paginate(paginator: GenericPaginatorState): void;
}
