/**
 * @author Carlos Duardo <carlos.duardo@qualud.es>
 */
export class HelperTools {


  public static generateHash(
    /**
     * This function generates a random hash string with the specified length and character set options.
     *
     * @param {number} length - The length of the hash string to be generated.
     * @param {boolean} hasSymbols - Whether to include symbols in the character set.
     * @param {boolean} hasNumber - Whether to include numbers in the character set.
     * @param {boolean} lowercase - Whether to include lowercase letters in the character set.
     * @param {boolean} hasSpecialCharacters - Whether to include special character set.
     *
     * @returns {string} - The randomly generated hash string.
     */
    {
      length = 10,
      hasSymbols = true,
      hasNumber = true,
      lowercase = false,
      hasSpecialCharacters = false
    }: {
      length?: number,
      hasSymbols?: boolean,
      hasNumber?: boolean,
      lowercase?: boolean
      hasSpecialCharacters?: boolean,
    } = {}) {

    let charset = 'abcdefghijklmnopqrstuvwxyz';

    if (!lowercase) {
      charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    if (hasSymbols) {
      charset += '!@#$%&*';
    }
    if (hasSpecialCharacters) {
      charset += 'ñáéíóúç';
    }

    if (hasNumber) {
      charset += '0123456789';
    }

    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  public static generateSlug({word, upperCase}: { word: string, upperCase?: boolean }) {
    const slug = word
      .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
      .replace(/(a-z0-9)([A-Z])/, '$1_$2')
      .replace(/ /g, '_');

    return upperCase ? slug.toUpperCase() : slug.toLowerCase();
  }

  public static idToIri(endpoint: string, id: number): string {
    return `/api/${endpoint}/${id}`;
  }

  public static iriToId(iri: string): number {
    if (iri) {
      const parts = iri.split('/');
      return Number(parts[parts.length - 1]);
    }
    return -1;
  }

  public static getIriEndpoint(iri: string): string | null {
    if (iri) {
      const parts = iri.split('/');
      return parts[2] || null;
    }
    return null;
  }

  public static dateToEpoch(date: string | Date | null | undefined, roundValue: boolean = true) {
    if (null === date || undefined === date) return null

    if (typeof date === 'string') {
      date = new Date(date);
    }
    return roundValue ? Math.floor(date.getTime() / 1000) : date.getTime() / 1000;
  }

  public static epochToDate(epoch: number | null | undefined) {
    if (null === epoch || undefined === epoch) return null
    return new Date(Math.floor(epoch * 1000));
  }

  public static getRandomInteger(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static getRandomElements<T>(collection: T[], count: number = 1): | T | T[] | null {
    const length = collection.length;

    if (length === 0) {
      return null;
    }

    if (count >= length) {
      return collection;
    }

    if (1 === count) {
      return collection[this.getRandomInteger(0, length - 1)]
    }

    const shuffled = collection.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }

  public static extractColumnFromCollection(
    {
      collection,
      column,
      startIndex = null,
      maxResult = null
    }: {
      collection: any[],
      column: string,
      startIndex?: number | null,
      maxResult?: number | null,
    }
  ): any[] {
    let arrayColumValues = collection.map(item => item[column]);

    if (startIndex) {
      arrayColumValues = arrayColumValues.splice(startIndex);
    }

    if (maxResult) {
      arrayColumValues = arrayColumValues.splice(0, maxResult);
    }

    return arrayColumValues;
  }

  public static round(value: number, precision: number = 0) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

}

/**
 * This is a TypeScript class called "FunctionsHelper" that contains several static methods for logging and error handling.
 * This class can be used to simplify logging and error handling in TypeScript projects.
 */
export class FunctionsHelper {
  public static void() {
  }

  /**
   * The "log" method logs a value to the console using the "console.dir" method.
   * @param value
   */
  public static log(value: any) {
    console.dir('FunctionsHelper::logger', value)
  }

  /**
   * The "logSame" method logs a value using the "log" method and then returns the value.
   * @param value
   */
  public static logSame(value: any) {
    this.log(value)
    return value
  }

  /**
   * The "errorSame" method logs an error using the "error" method and then returns the value.
   * @param value
   */
  public static errorSame(value: any) {
    this.error(value)
    return value
  }

  /**
   * The "error" method logs an error to the console using the "console.error" method.
   * @param value
   */
  public static error(value: any) {
    console.error('FunctionsHelper::error', value);
  }


}
